import React, { useState } from 'react';
import './pages_documentos.css';

export default function RREO() {
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };

  return (
    <main>
      <section className="ftco-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 text-center mb-5">
              <br /> <br />
              <div class="text-with-gradient">
								<div class="gradient-line"></div>
								<div class="text-container">
									<span>RREO</span>
								</div>
								<div class="gradient-line"></div>
							</div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {!iframeLoaded && (
                <div className="loading-overlay">
                  <p className="loading-text">Carregando conteudo, por favor aguarde...</p>
                </div>
              )}
              <iframe
                src="https://transparencia.elmartecnologia.com.br/Contab/Relatorios/RREO?Tab=1&isModal=false&ctx=201084"
                style={{ border: '0px solid #ccc', width: '100%', height: '1000px' }}
                title="RREO"
                onLoad={handleIframeLoad}
              ></iframe>
            </div>
          </div>
          <br /> <br /> <br /> <br />
        </div>
      </section>
    </main>
  );
}
