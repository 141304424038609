import React, { useEffect, useState } from 'react';
import './css/SalaEmpreendedor.css';
import { getDatabase, ref, onValue } from 'firebase/database';
import firebaseApp, { KEY_REF } from './adm/admin/firebaseConfig';

export default function SalaEmpreendedor() {

  function removePTags(html) {

    return html.replace(/<p>/g, '').replace(/<\/p>/g, '').replace(/<ol>.*?<\/ol>/gs, '').replace(/<li>.*?<\/li>/gs, '');
  }


  const [noticias, setNoticias] = useState([]);

  useEffect(() => {
    const databaseInstance = getDatabase(firebaseApp);
    const noticiasRef = ref(databaseInstance, `${KEY_REF}/saladoempreendedor`);

    onValue(noticiasRef, (snapshot) => {
      const data = snapshot.val();

      if (data) {
        const avisosArray = Object.keys(data).map((key) => ({
          id: key,
          date: data[key].data,
          titulo: data[key].titulo,
          descricao: data[key].descricao,
          numero: data[key].numero,
          modalidade: data[key].modalidade,
          arquivo: data[key].arquivo,
          baixavel: data[key].baixavel,
          horario: data[key].horario,
          documentoUrl: data[key].documentoUrl,
          ativo: data[key].ativo,
        }));

        const avisosAtivos = avisosArray.filter((aviso) => aviso.ativo);
        const avisosOrdenados = avisosAtivos.sort((a, b) => new Date(b.data) - new Date(a.data));

        setNoticias(avisosOrdenados);
      }
    });
  }, []);

  const renderNoticias = (noticia) => {
    const truncatedDescricao = noticia.descricao.length > 50
      ? noticia.descricao.slice(0, 800) + "..."
      : noticia.descricao;

    const tituloParaURL = noticia.titulo.replace(/\s+/g, '-').toLowerCase();

    const descricaoSemPTags = removePTags(truncatedDescricao);

    return (
      <div class="d-flex text-body-secondary pt-3" key={noticia.id}>
        <a class="bd-placeholder-img flex-shrink-0 me-2 rounded" width="60" height="60"><i class="fa-regular fa-newspaper"></i></a>
        <p class="pb-3 mb-0 small lh-sm border-bottom" style={{ marginLeft: "1%", marginRight: "2%" }}>
          <strong class="d-block text-gray-dark">{noticia.titulo} - {noticia.numero}</strong>
          <strong class="d-block text-gray-dark"><b style={{ color: "red" }}>
            {noticia.ativo ? <span><br /> </span> : <span><br />ATENÇÃO - ESTE AVISO JÁ PASSOU DA DATA.<br /><br /></span>}
          </b></strong>
          {truncatedDescricao}
          <strong class="d-block text-gray-dark"><br />{noticia.date} - {noticia.horario} - {noticia.modalidade} <a href={noticia.documentoUrl}> {noticia.baixavel ? <span> - <i class="fa-solid fa-file-arrow-down fa-beat-fade"></i> LINK DO ANEXO</span> : null}</a></strong>
          <strong class="d-block text-gray-dark"></strong>
        </p>

      </div>

    );
  };

  const renderUltimasNoticias = () => {
    if (noticias.length === 0) {
      return (
        <div class="alert alert-warning" role="alert">
          Não há eventos sociais ou avisos importantes para os próximos dias. Verifique novamente mais tarde.
        </div>
      );
    }

    const ultimasNoticias = noticias;
    return ultimasNoticias.map(renderNoticias);
  };



  return (



    <main>

      <section class="py-5 text-center container">
        <div class="row py-lg-5">
          <div class="col-lg-6 col-md-8 mx-auto">
            <img src="/imagens/salaempreendedor.png" alt="Sala do Empreendedor" width="280" height="135"></img>

            <p> <br /> </p>
            <p class="text-body-secondary"><i class="fa-solid fa-circle-info"></i> Bem vindo(a) a sala do Empreendedor.</p>
            <p class="text-body-secondary"><i class="fa-solid fa-location-dot"></i> Endereço: R. Vicente Borges Gurjão, Gurjão - PB, 58670-000</p>
            <p class="text-body-secondary"><i class="fa-solid fa-clock"></i> 08h as 14h (Segunda a Sexta)</p>
            <p class="text-body-secondary"><i class="fa-solid fa-user"></i> Agente Lindon Johnson Nascimento</p>


            <p>
              <a href="https://api.whatsapp.com/send?phone=558387367266" class="btn btn-primary my-2"><i class="fa-brands fa-whatsapp"></i> Whatsapp</a>
              <a> <i class="fa-solid fa-caret-up"></i> </a>
              <a href="https://www.instagram.com/saladoempreendedorgurjao" class="btn btn-secondary my-2"><i class="fa-brands fa-instagram"></i> Instagram</a>
            </p>
          </div>
        </div>
      </section>

      <div class="container">
        <div className="service-grid2">

          <a href="https://www.gov.br/empresas-e-negocios/pt-br/empreendedor/quero-ser-mei/atividades-permitidas"><div className="service-card2" >

            <img src={"/imagens/icons/contrato.png"} />
            <p>Atividades MEI</p>

          </div> </a>

          <a href="https://www.gov.br/empresas-e-negocios/pt-br/empreendedor/quero-ser-mei"><div className="service-card2" >

            <img src={"/imagens/icons/portarias.png"} />
            <p>Formalize-se</p>

          </div></a>

          <a href="https://api.whatsapp.com/send?phone=558387367266"><div className="service-card2" >

            <img src={"/imagens/icons/portarias.png"} />
            <p>Emissão de alvará</p>

          </div> </a>

          <a href="https://www8.receita.fazenda.gov.br/simplesnacional/aplicacoes/atspo/pgmei.app/identificacao"><div className="service-card2" >

            <img src={"/imagens/icons/nota_fiscal.png"} />
            <p>Emitir DAS</p>

          </div> </a>


          <a href="https://www.nfse.gov.br/EmissorNacional/Login?ReturnUrl=%2fEmissorNacional">
            <div className="service-card2" >
              <img src={"/imagens/icons/nota_fiscal.png"} />
              <p>Notas Fiscais MEI</p>

            </div> </a>

          <a href="enfse.elmartecnologia.com.br/201084">  <div className="service-card2" >

            <img src={"/imagens/icons/nota_fiscal.png"} />
            <p>Emissor de NF Municipal </p>

          </div></a>

          <a href="/saladoempreendedor/Licitações 2024.pdf">  <div className="service-card2" >

            <img src={"/imagens/icons/nota_fiscal.png"} />
            <p>Licitações 2024 </p>

          </div></a>

        </div>
      </div>

      <main class="container">
        <div class="d-flex align-items-center p-3 my-3 text-white bg-primary rounded shadow-sm">
          <a width="100" height="100"><i class="fa-solid fa-comments"></i></a>
          <div class="lh-1" style={{ marginLeft: "2%" }}>
            <h7>AVISOS IMPORTANTES - SALA DO EMPREENDENDOR</h7>
          </div>
        </div>

        <div class="my-3 p-3 bg-body rounded shadow-sm">
          <h6 class="border-bottom pb-2 mb-0">Mantenha-se informado por dentro das últimas novidades e eventos da sala do Empreendedor.</h6>

          {renderUltimasNoticias()}


        </div>

      </main>

      <div class="album py-5 bg-body-tertiary">

        <div class="container">
          <h3 class="titulo_p"><i class="fa-solid fa-video fa-flip"></i> • Videos disponibilizados pela sala do empreendedor</h3>
          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">

            <div class="col">
              <div class="card shadow-sm">
                <iframe class="bd-placeholder-img card-img-top" width="100%" height="225" src="https://www.youtube.com/embed/TgjNNA7wy14" title="Conheça os DIREITOS e DEVERES do MEI 👩🏼‍💻 Aproveite os BENEFÍCIOS e não tenha pendências" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <title></title><rect width="100%" height="100%" fill="#55595c"></rect><text x="50%" y="50%" fill="#eceeef" dy=".3em"></text>
                <div class="card-body">
                  <p class="card-text">Quais são os direitos e deveres de quem é MEI? Fique por dentro de todos os benefícios e das suas responsabilidades neste vídeo.</p>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="btn-group">
                      <a href='https://youtu.be/TgjNNA7wy14'><button type="button" class="btn btn-sm btn-outline-secondary">Visualizar</button></a>
                      <button type="button" class="btn btn-sm btn-outline-secondary">Gostei</button>
                    </div>
                    <small class="text-body-secondary">7 min e 23 seg</small>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card shadow-sm">
                <iframe class="bd-placeholder-img card-img-top" width="100%" height="225" src="https://www.youtube.com/embed/0JVqmJFAhAg" title="Como EMITIR NOTA FISCAL MEI de SERVIÇOS 🤔 PASSO A PASSO ATUALIZADO 2023" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <title></title><rect width="100%" height="100%" fill="#55595c"></rect><text x="50%" y="50%" fill="#eceeef" dy=".3em"></text>
                <div class="card-body">
                  <p class="card-text">MEI, você sabe como emitir notas fiscais de prestação de serviços na plataforma da Receita Federal? Acompanhe o passo a passo que preparamos! </p>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="btn-group">
                      <a href="https://www.youtube.com/watch?v=0JVqmJFAhAg"><button type="button" class="btn btn-sm btn-outline-secondary">Visualizar</button></a>
                      <button type="button" class="btn btn-sm btn-outline-secondary">Gostei</button>
                    </div>
                    <small class="text-body-secondary">6 min 25 seg</small>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card shadow-sm">
                <iframe class="bd-placeholder-img card-img-top" width="100%" height="225" src="https://www.youtube.com/embed/AtAqx9LEfho" title="MEI pode ser CLT? 🤔 Direitos e benefícios: saiba o que muda se você for CLT e se formalizar como MEI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <title></title><rect width="100%" height="100%" fill="#55595c"></rect><text x="50%" y="50%" fill="#eceeef" dy=".3em"></text>
                <div class="card-body">
                  <p class="card-text">MEI pode ser CLT? CLT pode ser MEI? E o
                    deveres? Descubra o que muda se você decidir ser MEI e CTL ao mesmo tempo assistindo a este vídeo!</p>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="btn-group">
                      <a href='https://www.youtube.com/watch?v=AtAqx9LEfho&pp=ygUUdmFudGFnZW5zIG1laSBzZWJyYWU%3D'><button type="button" class="btn btn-sm btn-outline-secondary">Visualizar</button></a>
                      <button type="button" class="btn btn-sm btn-outline-secondary">Gostei</button>
                    </div>
                    <small class="text-body-secondary">3 min e 14 seg</small>
                  </div>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card shadow-sm">
                <iframe class="bd-placeholder-img card-img-top" width="100%" height="225" src="https://www.youtube.com/embed/EBX85oRUjaM" title="Descubra AGORA o que acontece se não pagar o MEI. 😱 Não pagar a DAS suja o nome?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <div class="card-body">
                  <p class="card-text">Se você é MEI e esqueceu de pagar a guia DAS, assista ao vídeo e descubra quais são os riscos e como regularizar a situação!</p>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="btn-group">
                      <a href='https://www.youtube.com/watch?v=EBX85oRUjaM'> <button type="button" class="btn btn-sm btn-outline-secondary">Visualizar</button></a>
                      <a href="#">   |   </a>
                      <a href='https://www8.receita.fazenda.gov.br/simplesnacional/aplicacoes/atspo/pgmei.app/identificacao'><button type="button" class="btn btn-sm btn-outline-secondary"><i class="fa-solid fa-file-invoice-dollar fa-beat-fade"></i> Guia Pagamento</button></a>

                    </div>
                    <small class="text-body-secondary">7 min 15 seg</small>
                  </div>
                </div>
              </div>
            </div>


            <div class="col">
              <div class="card shadow-sm">
                <iframe class="bd-placeholder-img card-img-top" width="100%" height="225" src="https://www.youtube.com/embed/On4Ge4BRuhw" title="Como SOLICITAR EMPRÉSTIMO como MEI? 🤑 3 PASSOS FÁCEIS! Saiba tudo sobre o empréstimo do PRONAMPE." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                <div class="card-body">
                  <p class="card-text">Você é MEI e está pensando em pedir empréstimo? Precisa de mais recursos financeiros? assista o video e entre em contato com a sala do empreendedor</p>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="btn-group">
                      <a href='https://www.youtube.com/watch?v=On4Ge4BRuhw'><button type="button" class="btn btn-sm btn-outline-secondary">Visualizar</button></a>

                      <button type="button" class="btn btn-sm btn-outline-secondary">Gostei</button>
                    </div>
                    <small class="text-body-secondary">7 min 24 seg</small>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card shadow-sm">
                <iframe class="bd-placeholder-img card-img-top" width="100%" height="225" src="https://www.youtube.com/embed/f2vFZaiUeDY" title="MEI precisa ter ALVARÁ? 🤔 Descubra como INICIAR suas ATIVIDADES" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <div class="card-body">
                  <p class="card-text">O que o microempreendedor deve aprender para abrir a sua empresa? Precisa de alvará? Como pedir dispensa? Saiba tudo neste vídeo. </p>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="btn-group">
                      <a href='https://www.youtube.com/watch?v=f2vFZaiUeDY'>  <button type="button" class="btn btn-sm btn-outline-secondary">Visualizar</button>
                      </a>
                      <button type="button" class="btn btn-sm btn-outline-secondary">Gostei</button>
                    </div>
                    <small class="text-body-secondary">6 min 30 seg</small>
                  </div>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card shadow-sm">
                <iframe class="bd-placeholder-img card-img-top" width="100%" height="225" src="https://www.youtube.com/embed/Xw0vsmEfcxU" title="Gurjão lança aplicativo para comerciantes locais" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <div class="card-body">
                  <p class="card-text">Através do app Gurjão Plus, os clientes da região poderão visualizar todos os serviços que a cidade disponibiliza e ainda realizar seus pedidos pelo própria plataforma. Tanto comerciantes, prestadores de serviços e consumidores terão acesso a mais informação, qualidade no atendimento e comodidade nas compras ou negociações.</p>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="btn-group">
                      <a href='https://www.youtube.com/watch?v=Xw0vsmEfcxU'> <button type="button" class="btn btn-sm btn-outline-secondary">Visualizar</button></a>
                      <a href="#">   |   </a>
                      <a href='https://gurjaoplus.bigstarsoftwares.com.br'>  <button type="button" class="btn btn-sm btn-outline-secondary"><i class="fa-solid fa-download fa-bounce"></i> Baixar o Aplicaitvo</button></a>
                    </div>
                    <small class="text-body-secondary">2 min 33 seg</small>
                  </div>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card shadow-sm">
                <iframe class="bd-placeholder-img card-img-top" width="100%" height="225" src="https://www.youtube.com/embed/rjDcVXxZ9Oc" title="Como MIGRAR de MEI para ME ✅ Descubra COMO FAZER de forma RÁPIDA e SEGURA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <div class="card-body">
                  <p class="card-text">Confira no vídeo o tutorial completo para fazer essa migração de forma rápida e sem falhas.</p>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="btn-group">
                      <a href='https://www.youtube.com/watch?v=rjDcVXxZ9Oc'>  <button type="button" class="btn btn-sm btn-outline-secondary">Visualizar</button>
                      </a>
                      <button type="button" class="btn btn-sm btn-outline-secondary">Gostei</button>
                    </div>
                    <small class="text-body-secondary">7 min 19 seg</small>
                  </div>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card shadow-sm">
                <iframe class="bd-placeholder-img card-img-top" width="100%" height="225" src="https://www.youtube.com/embed/B6bv5fbmVFs" title="Oficina - Emissão de Nota Fiscal" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <rect width="100%" height="100%" fill="#55595c"></rect><text x="50%" y="50%" fill="#eceeef" dy=".3em"></text>
                <div class="card-body">
                  <p class="card-text">Dia 18 de outubro de 2023 foi realizado a Oficina de Emissão de nota fiscal com o sebrae, assista o video e saiba como foi..</p>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="btn-group">
                      <a href='https://www.youtube.com/watch?v=B6bv5fbmVFs'><button type="button" class="btn btn-sm btn-outline-secondary">Visualizar</button></a>

                      <button type="button" class="btn btn-sm btn-outline-secondary">Gostei</button>
                    </div>
                    <small class="text-body-secondary">1 min 21 seg</small>
                  </div>
                </div>
              </div>
            </div>




          </div>

        </div>

        <div class="col-lg-2 col-md-2 mx-auto">
          <a href="#" class="btn btn-primary my-2"><i class="fa-solid fa-circle-up fa-beat-fade"></i> Voltar ao topo</a>

        </div>



      </div>

    </main>

  )
}
