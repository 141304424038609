import React from 'react';
import { Link } from 'react-router-dom';
import './css/LayoutBanner.css';

function Banner() {
  return (

    <>




      <div className="text-center" style={{ marginTop: 20, marginBottom: 20, marginLeft: 5, marginRight: 5 }}>

        <Link to={"https://play.google.com/store/apps/details?id=com.bigstarinformatica.gurjaoplus&hl=en_US"}>
          <img
            src="/imagens/gurjaoplus.png"
            className="img-fluid"
            alt="Banner"
            style={{ width: "1250px", height: "auto", borderRadius: 5 }}
          />
        </Link>

      </div>


      {/* 
      <div class="div-banner">


        <div class="bannerN-container">
          <Link to="https://play.google.com/store/apps/details?id=com.bigstarinformatica.gurjaoplus&hl=en_US"> <img style={{ height: "470px" }} src="./imagens/gurjaoplus.jpg" alt="gurjaoplus.bigstarsoftwares.com.br" class="bannergj"></img></Link>

        </div>


      </div>
  */}
    </>
  );
}

export default Banner;
