import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getDatabase, ref, get, child } from 'firebase/database';
import firebaseApp from '../adm/admin/firebaseConfig';

const databaseInstance = getDatabase(firebaseApp);

const TicketDetails = () => {
    alert('aqui')
  const { ticketId } = useParams();
  const [ticketData, setTicketData] = useState(null);

  useEffect(() => {
    const fetchTicketData = async () => {
      const dbRef = ref(databaseInstance);
      const snapshot = await get(child(dbRef, `pagamentos/${ticketId}`));
      if (snapshot.exists()) {
        setTicketData(snapshot.val());
        alert('aqui')
      } else {
        console.log("No data available");
        alert('aqui')
      }
    };

    fetchTicketData();
  }, [ticketId]);

  if (!ticketData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>Detalhes do Ticket</h1>
      <p>Nome: {ticketData.nome}</p>
      <p>CPF/CNPJ: {ticketData.tax}</p>
      <p>Categoria: {ticketData.categoria}</p>
      <p>Tamanho da Barraca: {ticketData.tamanho}</p>
      <p>Opção de Pagamento: {ticketData.opcaoPagamento}</p>
      <p>Status: {ticketData.status}</p>
      <p>Ticket #: {ticketData.ticket}</p>
    </div>
  );
};

export default TicketDetails;
