import React from 'react';
import QRCode from 'qrcode.react';

const TicketComponent = ({ data }) => {
  const ticketUrl = `http://localhost:3000/ticket/${data.ticket}`;

  return (
    <div>
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <style type="text/css">{`
          table {
              border-collapse: collapse;
              padding: 0;
              width: 100%;
          }

          td {
              padding: 0;
              vertical-align: top;
          }

          .ticket-title {
              color: #999;
              font-size: 16px;
              letter-spacing: 0;
              line-height: 16px;
              margin-top: 10px;
          }

          .ticket-info {
              color: #535353;
              font-size: 14px;
              line-height: 21px;
          }

          .ticket-wrapper {
              border: 2px solid #999;
              border-top: 12px solid rgb(33,150,243);
              margin: 15px auto 0;
              padding: 15px;
              width: 100%;
              max-width: 650px;
              box-sizing: border-box;
          }

          .ticket-wrapper:first-child {
              margin-top: 0;
          }

          .ticket-table {}

          .ticket-table .first-col {
              width: 570px;
          }

          .ticket-logo {
              border-left: 2px dashed #ccc;
              text-align: center;
              vertical-align: middle;
          }

          .ticket-logo img {
              height: 50px;
              width: 50px;
          }

          .ticket-name-div {
              border-bottom: 2px dotted #ccc;
              margin: 0 12px 0 22px;
              padding: 15px 0px 15px 0;
              text-align: left;
          }

          .ticket-event-longtitle {
              color: #535353;
              font-size: 22px;
              letter-spacing: -1px;
              line-height: 22px;
          }

          .ticket-event-shorttitle {
              color: #535353;
              font-size: 18px;
              letter-spacing: -1px;
              line-height: 22px;
          }

          .ticket-event-details {
              border-bottom: 2px dotted #ccc;
              margin: 0 12px 0px 22px;
              padding: 15px 0px 15px 0;
              text-align: left;
          }

          .ticket-event-details .first-col {
              text-align: left;
              width: 40%;
          }

          .ticket-event-details .second-col {
              text-align: right;
              vertical-align: top;
              width: 60%;
          }

          .ticket-venue {
              color: #535353;
              font-size: 14px;
              line-height: 21px;
          }

          .ticket-venue:first-child {
              font-size: 16px;
          }

          .ticket-ticket-details {
              margin: 0 12px 0px 22px;
              text-align: left;
          }

          .ticket-ticket-details .first-col {
              border-right: 2px dashed #ccc;
              padding-top: 4px;
              text-align: left;
              vertical-align: top;
              width: 150px;
          }

          .ticket-ticket-details .second-col {
              padding: 4px 0px 0px 32px;
              text-align: left;
              width: 225px;
          }

          .ticket-ticket-details .third-col {
              text-align: right;
          }

          .ticket-qr-code {
              height: 50px;
              margin-top: 10px;
          }

          /* Print specific styles */
          @media print {
              a[href]:after, abbr[title]:after {
                  content: "";
              }

              .ticket-wrapper {
                  width: 16cm;
              }

              .ticket-table .first-col {
                  width: 13.8cm;
              }

              .ticket-logo img {
                  height: auto;
                  max-width: 100%;
              }

              .ticket-ticket-details .first-col {
                  width: 4cm;
              }

              .ticket-ticket-details .second-col {
                  width: 6cm;
              }

              .ticket-ticket-details .third-col {
                  width: 2.5cm;
              }

              @page {
                  margin: 1cm;
              }
          }
        `}</style>
      </head>
      <body>
        <div className="ticket-wrapper">
          <table className="ticket-table">
            <tr>
              <td className="first-col">
                <div className="ticket-name-div">
                  <span className="ticket-event-longtitle">COMPROVANTE DE INSCRIÇÃO</span>
                </div>
                <div className="ticket-event-details">
                  <table>
                    <tr>
                      <td className="first-col">
                        <div className="ticket-info">
                          Categoria: {data.categoria}
                        </div>
                        <div className="ticket-title">
                          PERMISSÃO
                        </div>
                        <div className="ticket-info">
                          {data.tamanho}
                        </div>
                      </td>
                      <td className="second-col">
                        <div className="ticket-venue">
                          {data.nome}
                        </div>
                        <div className="ticket-venue">
                          CPF/CNPJ: {data.tax}
                        </div>
                        <div className="ticket-venue">
                          BODE NA RUA 22º EDIÇÃO
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="ticket-ticket-details">
                  <table>
                    <tr>
                      <td className="first-col">
                        <div className="ticket-title">
                          TICKET #
                        </div>
                        <div className="ticket-info">
                          {data.ticket}
                        </div>
                        <div className="ticket-title">
                          VALOR
                        </div>
                        <div className="ticket-info">
                          R$ 66,75
                        </div>
                      </td>
                      <td className="second-col">
                        <div className="ticket-title">
                          PAGAMENTO
                        </div>
                        <div className="ticket-info">
                          {data.opcaoPagamento}
                        </div>
                        <div className="ticket-title">
                          STATUS
                        </div>
                        <div className="ticket-info">
                          {data.status}
                        </div>
                      </td>
                      <td className="third-col">
                        <QRCode className="ticket-qr-code" value={ticketUrl} />
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
              <td className="ticket-logo">
                <img src="/imagens/logo512.png" alt="Logo" />
              </td>
            </tr>
          </table>
        </div>
      </body>
    </div>
  );
};

export default TicketComponent;
