import React from 'react'
import './pages_documentos.css';

export default function Documentos() {
	return (

		<main>

			<section class="ftco-section">
				<div class="container">
					<div class="row justify-content-center">
						<div class="col-md-6 text-center mb-5">
							<br />
							<div class="text-with-gradient">
								<div class="gradient-line"></div>
								<div class="text-container">
									<span>DOCUMENTOS</span>
								</div>
								<div class="gradient-line"></div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="table-wrap">
								<table class="table">
									<thead class="thead-primary">
										<tr>

											<th>Nome</th>

											<th>Arquivo Baixável</th>
											<th>Data Publicação</th>
										</tr>
									</thead>
									<tbody>
										<tr>

											<td>ESTATUTO DO SERVIDOR</td>

											<td><a href="/documentos/rh/Estatuto do Servidor.pdf">Download</a></td>
											<td>11/11/2023</td>
										</tr>


									</tbody>

								</table>
								<br /> <br />
							</div>
						</div>
					</div>
					<br /> <br /> <br /> <br />
				</div>
			</section>

		</main>

	)
}
