import React from 'react'
import './css/Transparencia.css';
import { Link } from 'react-router-dom';

export default function SalaEmpreendedor() {
    return (

        <>
            <br /><br />

            <div class="text-with-gradient" style={{ marginBottom: 15 }}>
                <div class="gradient-line"></div>
                <div class="text-container">
                    <span>TRANSPARENCIA</span>
                </div>
                <div class="gradient-line"></div>
            </div>
            <section class="marginPD">
                <div class="">
                    <div class="row py-3">
                        <div class="col-12">
                            <h4 class="mb-0">Canais de Atendimento</h4>
                            <p class="mb-0">Lei Nº 12.527 (Acesso a Informação) - Lei Nº 13.460 (Carta de Serviços)</p>
                        </div>
                    </div>
                    <div class="row row-cols-1 row-cols-sm-1 row-cols-md-3 py-3">
                        <div class="col">
                            <a href="#" class="stretched-link bs-solicitar-informacoes-link text-decoration-none custom-card">
                                <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                    <div class="card-body tm-sic">
                                        <i class="fas fa-info-circle fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">e-SIC</h3>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col">
                            <a href="#" target="_top" class="stretched-link bs-ouvidoria-link text-decoration-none custom-card">
                                <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                    <div class="card-body">
                                        <i class="fas fa-phone-alt fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Ouvidoria</h3>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col">
                            <a href="#" class="stretched-link bs-portaldeservicos-link text-decoration-none custom-card">
                                <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                    <div class="card-body">
                                        <i class="fas fa-envelope fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Carta de Serviços</h3>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>


            <section class="marginPD">
                <div class="">
                    <div class="row py-3">
                        <div class="col-12">
                            <h4 class="mb-0">Receitas e Despesas</h4>
                            <p class="mb-0">Lei Nº 12.527 (Acesso a Informação) - Lei Complementar Nº 131 (Transparência)</p>
                        </div>
                    </div>
                    <div class="row row-cols-1 row-cols-sm-1 row-cols-md-3 py-3">
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div itemscope itemtype="https://turmalina.tcepb.tc.br/documentation/budgetRevenueSource" class="card-body">
                                    <a href="https://transparencia.elmartecnologia.com.br/Contab/Receitas/Prevista?Tab=1&isModal=false&ctx=201084" class="stretched-link bs-receitas-orcamentarias-link text-decoration-none">
                                        <i class="fas fa-plus-square fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Receita Orçamentária</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div itemscope itemtype="https://turmalina.tcepb.tc.br/documentation/extraBudgetRevenueID" class="card-body">
                                    <a href="https://transparencia.elmartecnologia.com.br/Contab/Receitas/Extra?Tab=3&isModal=false&ctx=201084" class="stretched-link bs-receitas-extraorcamentarias-link text-decoration-none">
                                        <i class="fas fa-plus-square fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Receita Extraorçamentária</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div itemscope itemtype="https://turmalina.tcepb.tc.br/documentation/budgetExpenditureProgram" class="card-body">
                                    <a href="https://transparencia.elmartecnologia.com.br/Contab/Despesas/Fixada?Tab=1&isModal=false&ctx=201084" class="stretched-link bs-despesas-orcamentarias-link text-decoration-none">
                                        <i class="fas fa-minus-square fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Despesa Orçamentária</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div itemscope itemtype="https://turmalina.tcepb.tc.br/documentation/extraBudgetExpenditureDescription" class="card-body">
                                    <a href="https://transparencia.elmartecnologia.com.br/Contab/Despesas/Extra?Tab=4&isModal=false&ctx=201084" class="stretched-link bs-despesas-extraorcamentarias-link text-decoration-none">
                                        <i class="fas fa-minus-square fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Despesa Extraorçamentária</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div itemscope itemtype="https://turmalina.tcepb.tc.br/documentation/PaymentDocument" class="card-body">
                                    <a href="https://transparencia.elmartecnologia.com.br/Contab/Despesas/Pagamentos?Tab=6&isModal=false&ctx=201084" class="stretched-link bs-documentos-de-pagamento-link text-decoration-none">
                                        <i class="fas fa-plus-square fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Documento de Pagamento</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section class="marginPD">
                <div class="">
                    <div class="row py-3">
                        <div class="col-12">
                            <h4 class="mb-0">Atos, publicações, pessoal, compras e convênios</h4>
                            <p class="mb-0">Lei Nº 12.527 (Acesso a Informação)</p>
                        </div>
                    </div>
                    <div class="row row-cols-1 row-cols-sm-1 row-cols-md-4 py-3">
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="#" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-file-alt fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Instrumentos de Planejamento</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <Link to={"/legislacao/leismunicipais"}>
                                        <a class="stretched-link bs--link text-decoration-none">
                                            <i class="fas fa-balance-scale fs-3 d-block mb-3"></i>
                                            <h3 class="h6 mb-0">Leis Municipais</h3>
                                        </a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <Link to={"/legislacao/decretos"}>
                                        <a class="stretched-link bs--link text-decoration-none">
                                            <i class="fas fa-file-alt fs-3 d-block mb-3"></i>
                                            <h3 class="h6 mb-0">Decretos</h3>
                                        </a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="#" class="stretched-link bs-despesas-com-pessoal-link text-decoration-none">
                                        <i class="fas fa-minus-square fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Subvenções Sociais</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="#" class="stretched-link bs-despesas-com-pessoal-link text-decoration-none">
                                        <i class="fas fa-minus-square fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Emendas Impositivas</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div itemscope itemtype="https://turmalina.tcepb.tc.br/documentation/EmployeeInformation" class="card-body">
                                    <a href="https://sistema.grupofbrito.com.br/quadropessoal/?Ini=PMGJ" class="stretched-link bs-despesas-com-pessoal-link text-decoration-none">
                                        <i class="fas fa-minus-square fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Pessoal</h3>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body" itemscope itemtype="https://turmalina.tcepb.tc.br/documentation/Bidding">
                                    <a href="https://transparencia.elmartecnologia.com.br/Licitacao?Tab=1&isModal=false&ctx=201084" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-gavel fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Licitações</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <Link to={"/legislacao/diariosoficial"}>
                                        <a class="stretched-link bs--link text-decoration-none">
                                            <i class="fas fa-file-alt fs-3 d-block mb-3"></i>
                                            <h3 class="h6 mb-0">Publicações</h3>
                                        </a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="https://transparencia.elmartecnologia.com.br/Licitacao/Index/Contratos?Tab=2&isModal=false&ctx=201084" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-briefcase fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Contratos licitatórios</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div itemscope itemtype="https://turmalina.tcepb.tc.br/documentation/Contract" class="card-body">
                                    <Link to={"/arquivos/contratos"}>
                                        <a class="stretched-link bs--link text-decoration-none">
                                            <i class="fas fa-file-alt fs-3 d-block mb-3"></i>
                                            <h3 class="h6 mb-0">Contratos</h3>
                                        </a>
                                    </Link>

                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div itemscope itemtype="https://turmalina.tcepb.tc.br/documentation/Agreement" class="card-body">
                                    <a href="https://portaldatransparencia.gov.br/convenios/consulta?paginacaoSimples=true&tamanhoPagina=&offset=&direcaoOrdenacao=asc&periodoLiberacaoRecursosDe=01%2F08%2F2023&periodoLiberacaoRecursosAte=31%2F08%2F2023&uf=PB&nomeMunicipio=gurjão&colunasSelecionadas=linkDetalhamento%2CnumeroConvenio%2Cuf%2CmunicipioConvenente%2Csituacao%2CtipoTransferencia%2Cobjetivo%2CorgaoSuperior%2Corgao%2Cconcedente%2Cconvenente%2CdataInicioVigencia%2CdataFimVigencia%2CvalorLiberado%2CvalorCelebrado" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-handshake fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Convênios Federal</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="https://portaldatransparencia.gov.br/transferencias/consulta?paginacaoSimples=true&tamanhoPagina=&offset=&direcaoOrdenacao=asc&uf=PB&nomeMunicipio=GURJÃO&colunasSelecionadas=linkDetalhamento%2Cuf%2Cmunicipio%2Ctipo%2CtipoFavorecido%2Cacao%2ClinguagemCidada%2CgrupoDespesa%2CelementoDespesa%2CmodalidadeDespesa%2Cvalor&ordenarPor=mesAno&direcao=desc" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-building fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Recursos Federais</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="#" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-list-alt fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Estagiários</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="https://transparencia.elmartecnologia.com.br/Contab/Despesas/Empenhos?Tab=2&isModal=false&hTab=1%2C4%2C5&Filter=COVID19&ctx=201084" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-heartbeat fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">COVID-19</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="/arquivos/frota" class="stretched-link bs--link text-decoration-none">
                                        <i class="fa fa-car fs-3 d-block mb-3" aria-hidden="true"></i>
                                        <h3 class="h6 mb-0">Frota Municipal</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="marginPD" >

                <div itemscope itemtype="https://turmalina.tcepb.tc.br/documentation/PlanningInstrument">
                    <div class="row py-3">
                        <div class="col-12">
                            <h4 class="mb-0">Regulamentações</h4>
                            <p class="mb-0">Normas, Regulamentações, Pareceres, Planos e Informativos</p>
                        </div>
                    </div>
                    <div class="row row-cols-1 row-cols-sm-1 row-cols-md-4 py-3">
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="/arquivos/rreo" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-file fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">RREO</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="/arquivos/rgf" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-file fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">RGF</h3>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div itemprop="annualBudgetLaw" class="card-body">
                                    <a href="/arquivos/loa" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-file fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">LOA</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div itemprop="budgetGuidelinesLaw" class="card-body">
                                    <a href="/arquivos/ldo" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-file fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">LDO</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div itemprop="multiyearPlan" class="card-body">
                                    <a href="/arquivos/ppa" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-file fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">PPA</h3>
                                    </a>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>


            </section>

            <section class="marginPD">
                <div class="">
                    <div class="row py-3">
                        <div class="col-12">
                            <h4 class="mb-0">Regulamentações</h4>
                            <p class="mb-0">Normas, Regulamentações, Pareceres, Planos e Informativos</p>
                        </div>
                    </div>
                    <div class="row row-cols-1 row-cols-sm-1 row-cols-md-4 py-3">
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="#" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-file-alt fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Regulamentação da LAI</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="#" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-file-alt fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Regulamentação das Diárias</h3>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="#" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-file-alt fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Renúncias Fiscais</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="#" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-file-alt fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Informações de LGPD</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="#" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-file-alt fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Tabela de Valores das Diárias</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>





        </>

    )
}
