import React, { useState } from 'react';


export default function Cultura() {

    const [secretario] = useState({
        nome: 'Elenilson Garcia ',
        descricao: "O Secretário de Cultura e Turismo Municipal desempenha um papel crucial na promoção e preservação do patrimônio cultural e no estímulo ao turismo local. Suas responsabilidades incluem o desenvolvimento de políticas culturais, organização de eventos e atividades culturais, além da gestão de espaços culturais. Ele também trabalha na criação de estratégias para promover o turismo, atrair visitantes e impulsionar a economia local. O secretário colabora com artistas, instituições culturais e empresas do setor turístico, visando fortalecer a identidade cultural da comunidade e proporcionar experiências enriquecedoras aos visitantes. Sua atuação contribui para o desenvolvimento socioeconômico e cultural do município.",
        foto: '/imagens/elenilson.jpeg',
      });
    

  return (
    <div className="container my-4 mx-auto" style={{ maxWidth: '1600px' }}>
    <div className="row justify-content-center">
      <div className="col-md-6">
        <div className="card">
          <div className="row">
            <div className="col-md-6">
              <img
                src={secretario.foto}
                alt={secretario.nome}
                className="card-img-top img-fluid d-none d-md-block"
                style={{ margin:"5%", maxWidth: '500px' }}
              />

              <div style={{margin:"5%", marginLeft:"9%"}}>
              <button onClick="" type="button" class="btn btn-sm btn-outline-secondary d-none d-md-inline">
      <i class="fa-solid fa-square-envelope"></i> E-MAIL
    </button>
    
    <button onClick="" type="button" class="btn btn-sm btn-outline-secondary d-none d-md-inline">
      <i class="fa-solid fa-phone" style={{ color: "#27b300" }}></i> TELEFONE
    </button>
    <button onClick="" type="button" class="btn btn-sm btn-outline-secondary d-none d-md-inline">
      <i class="fa-solid fa-clock"></i> HORÁRIOS
    </button>
              </div>
      
            </div>
            <div className="col-md-6">
  <div className="card-body">
    <h3 className="d-none d-md-block card-title">{secretario.nome}</h3>
    <p className="d-none d-md-block card-text text-justify" style={{padding: "1%"}}>{secretario.descricao}</p>
   
    
  </div>
</div>

          </div>
          <div className="d-md-none text-center">
            <img
              src={secretario.foto}
              alt={secretario.nome}
              className="card-img-top img-fluid"
              style={{ maxWidth: '300px' }}
            />
            <div className="text-center mt-3">
              <h3 className="card-title">{secretario.nome}</h3>
              <p className="card-text text-justify " style={{padding: "5%"}}>{secretario.descricao}</p>
   

              <button onClick="" type="button" class="btn btn-sm btn-outline-secondary"><i class="fa-solid fa-square-envelope"></i> E-MAIL</button>
                <button onClick="" type="button" class="btn btn-sm btn-outline-secondary"><i class="fa-solid fa-phone" style={{color: "#27b300"}}></i> TELEFONE </button>
                <button onClick="" type="button" class="btn btn-sm btn-outline-secondary"><i class="fa-solid fa-clock"></i> HORARIOS</button>

          </div>
            </div>
        
        </div>
      </div>
    </div>
  </div>
  
  
  
  );
}
