import React, { useEffect, useState } from 'react';
import { compareAsc, parseISO } from 'date-fns';
import './css/NewsGrid.css';
import { Link } from 'react-router-dom';
import { getDatabase, ref, onValue } from 'firebase/database';
import firebaseApp, { KEY_REF } from './adm/admin/firebaseConfig';


function removePTags(html) {

  return html.replace(/<p>/g, '').replace(/<\/p>/g, '').replace(/<ol>.*?<\/ol>/gs, '').replace(/<li>.*?<\/li>/gs, '');
}


export default function Noticias() {
  const [noticias, setNoticias] = useState([]);
  const [isMouseOver, setIsMouseOver] = useState(false);

  const handleMouseEnter = () => {
    setIsMouseOver(true);
  };

  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };

  useEffect(() => {
    const databaseInstance = getDatabase(firebaseApp);
    const noticiasRef = ref(databaseInstance, `${KEY_REF}/noticias`);

    onValue(noticiasRef, (snapshot) => {
      const data = snapshot.val();

      if (data) {
        const noticiasArray = Object.keys(data).map((key) => ({
          id: key,
          titulo: data[key].titulo,
          descricao: data[key].descricao,
          imagem: data[key].imagem,
          data: data[key].data,
        }));


        const noticiasOrdenadas = noticiasArray.sort((a, b) => new Date(b.data) - new Date(a.data));

        setNoticias(noticiasOrdenadas);
      }
    });
  }, []);


  const ordenarNoticiasPorData = (noticias) => {
    return noticias.sort((a, b) => compareAsc(parseISO(b.data), parseISO(a.data)));
  };

  const renderNoticias = (noticia, index) => {
    const truncatedDescricao = noticia.descricao.length > 50
      ? noticia.descricao.slice(0, 220) + "..."
      : noticia.descricao;

    const descricaoSemPTags = removePTags(truncatedDescricao);

    const tituloParaURL = noticia.titulo
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/\s+/g, '-')
      .toLowerCase();

    const isLargeNews = index === 0;
    const isTwoColumns = index === 1 || index === 2;
    const isNormal = index > 2;


    const descricaoNoticia = isTwoColumns ? noticia.descricao.slice(0, 227) + "..." : truncatedDescricao;


    return (
      <div
        className={`news-card ${isLargeNews ? 'large-news' : ''} ${isTwoColumns ? 'two-columns' : ''} ${isNormal ? 'normal' : ''} ${isMouseOver ? 'hovered' : ''}`}
        key={noticia.id}
        onMouseEnter={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
      >
        <a href={`/noticias/${tituloParaURL}/${noticia.id}`} rel="noopener noreferrer">
          {isLargeNews && isMouseOver && (
            <div className="new-label">NOVO!</div>
          )}
          <img src={noticia.imagem} alt={noticia.titulo} />
          <h2 style={{ textAlign: 'justify', fontSize: 17 }}>{noticia.titulo}</h2>
          <p style={{ textAlign: 'justify', fontSize: 13, fontStyle: 'italic' }} >{removePTags(descricaoNoticia)}</p>
          {isTwoColumns && (
            <button type="button" className="btn btn-primary">LEIA MAIS</button>
          )}
        </a>
      </div>
    );
  };

  const renderUltimasNoticias = () => {

    const noticiasOrdenadas = ordenarNoticiasPorData(noticias);

    const ultimasNoticias = noticiasOrdenadas.slice(0, 7);

    return ultimasNoticias.map(renderNoticias);
  };

  const renderEstatico = (noticia) => {
    const truncatedDescricao = noticia.descricao.length > 50
      ? noticia.descricao.slice(0, 55) + "..."
      : noticia.descricao;

    const descricaoSemPTags = removePTags(truncatedDescricao);

    return (
      <div className="news-card" key={noticia.id}>
        <a href="#">
          <img src={noticia.imagem} alt={noticia.titulo} />
          <h2>{noticia.titulo}</h2>
          <p>{descricaoSemPTags}</p>
        </a>
      </div>
    );
  };
  const hasNoticias = noticias && noticias.length > 0;


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };


  const serviceData = [
    {
      icon: './imagens/icons/cheque.png',
      name: 'Contra-cheque',
      href: '/rh/cc',
    },
    {
      icon: './imagens/icons/paulogustavo.jpg',
      name: 'Lei Paulo Gustavo',
      href: '/leipaulogustavo',
    },
    {
      icon: './imagens/icons/publicacao.png',
      name: 'Decretos',
      href: '/legislacao/decretos',
    },
    {
      icon: './imagens/icons/leis.png',
      name: 'Leis Municipais',
      href: '/legislacao/leismunicipais',
    },
    {
      icon: './imagens/icons/portarias.png',
      name: 'Portarias',
      href: '/legislacao/portarias',
    },
    {
      icon: './imagens/icons/transparencia.png',
      name: 'Transparência',
      href: '/transparencia',
    },
    {
      icon: './imagens/icons/diario.png',
      name: 'Diario Oficial',
      href: '/legislacao/diariosoficial',
    },
    {
      icon: './imagens/icons/iptu.png',
      name: 'IPTU 2022',
      href: '/app/iptu',
    },
    {
      icon: './imagens/icons/empreendedor.png',
      name: 'Sala do Empreendedor',
      href: '/saladoempreendedor',
    },
    {
      icon: './imagens/icons/esic.png',
      name: 'E-SIC',
      href: '#',
    },
    {
      icon: './imagens/icons/documentos.png',
      name: 'Editais',
      href: '/arquivos/editais',
    },
    {
      icon: './imagens/icons/ctributo.png',
      name: 'Codigo Tributario',
      href: '/legislacao/codigotributario',
    },




  ];

  const noticiasEstatico = [
    {

      titulo: '',
      descricao: '',
      imagem: 'http://sulmak.com.br/wp-content/uploads/2016/12/1285541001-gif-carregando-cocacolazerrooo.gif',
    },
    {

      titulo: '',
      descricao: '',
      imagem: 'http://sulmak.com.br/wp-content/uploads/2016/12/1285541001-gif-carregando-cocacolazerrooo.gif',
    },
    {

      titulo: '',
      descricao: '',
      imagem: 'http://sulmak.com.br/wp-content/uploads/2016/12/1285541001-gif-carregando-cocacolazerrooo.gif',
    },
    {

      titulo: '',
      descricao: '',
      imagem: 'http://sulmak.com.br/wp-content/uploads/2016/12/1285541001-gif-carregando-cocacolazerrooo.gif',
    },
    {

      titulo: '',
      descricao: '',
      imagem: 'http://sulmak.com.br/wp-content/uploads/2016/12/1285541001-gif-carregando-cocacolazerrooo.gif',
    },
    {

      titulo: '',
      descricao: '',
      imagem: 'http://sulmak.com.br/wp-content/uploads/2016/12/1285541001-gif-carregando-cocacolazerrooo.gif',
    },
    {

      titulo: '',
      descricao: '',
      imagem: 'http://sulmak.com.br/wp-content/uploads/2016/12/1285541001-gif-carregando-cocacolazerrooo.gif',
    },
    {

      titulo: '',
      descricao: '',
      imagem: 'http://sulmak.com.br/wp-content/uploads/2016/12/1285541001-gif-carregando-cocacolazerrooo.gif',
    },

  ];


  return (

    <div style={{ backgroundColor: '#f5f5f5', marginBottom: 25 }}>
      <div className="news-grid-container2" >
        <div>

          <div class="text-with-gradient">
            <div class="gradient-line"></div>
            <div class="text-container">
              <span>FIQUE INFORMADO</span>
            </div>
            <div class="gradient-line"></div>
          </div>


          {hasNoticias ? (
            <div className="news-grid">
              {renderUltimasNoticias()}
            </div>
          ) : (
            <div className="news-grid">
              {noticiasEstatico.map(renderEstatico)}
            </div>
          )}
        </div>

        <div style={{ alignItems: 'center', justifyContent: 'center' }}>

          <a className="csala" href="/"> <Link to="/saladoempreendedor">
            <img src="/imagens/salaempreendedor.png" className='logosala' alt="sala empreendedor" width="200" height="100" />
          </Link></a>

          <div class="text-with-gradient" style={{ marginLeft: 0, alignItems: 'center', justifyContent: 'center' }}>
           
            <div class="text-container">
              <span style={{ fontSize: 20 }}>ACESSO RAPIDO</span>
            </div>
           
          </div>

          <div className="service-grid3">
            {serviceData.map((service, index) => (
              <Link style={{ textDecoration: "none" }} to={service.href} >
                <div className="service-card" >

                  <a><img src={service.icon} alt={service.name} />
                    <p>{service.name}</p></a>

                </div>
              </Link>

            ))}
          </div>

          <Link to={"/noticias/todas"} onClick={scrollToTop}>
            <div className="butao" style={{ paddingLeft: 50 }}>
              <button class="buttonp">
                LER TODAS AS NOTICIAS
                <svg fill="currentColor" viewBox="0 0 24 24" class="iconp">
                  <path clip-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z" fill-rule="evenodd"></path>
                </svg>
              </button>
            </div>
          </Link>

          {/* 
          <Link to={"/noticias/todas"} onClick={scrollToTop}>
            <div className="butao" style={{ marginTop: 15, marginBottom: 15 }}>
              <a class="playstore-button" href="https://play.google.com/store/apps/details?id=com.bigstarinformatica.gurjaoplus&hl=en_US" style={{ textDecoration: 'none' }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="icon" viewBox="0 0 512 512">
                  <path d="M99.617 8.057a50.191 50.191 0 00-38.815-6.713l230.932 230.933 74.846-74.846L99.617 8.057zM32.139 20.116c-6.441 8.563-10.148 19.077-10.148 30.199v411.358c0 11.123 3.708 21.636 10.148 30.199l235.877-235.877L32.139 20.116zM464.261 212.087l-67.266-37.637-81.544 81.544 81.548 81.548 67.273-37.64c16.117-9.03 25.738-25.442 25.738-43.908s-9.621-34.877-25.749-43.907zM291.733 279.711L60.815 510.629c3.786.891 7.639 1.371 11.492 1.371a50.275 50.275 0 0027.31-8.07l266.965-149.372-74.849-74.847z"></path>
                </svg>
                <span class="texts">
                  <span class="text-1">APP GURJÃO</span>
                  <span class="text-2">Google Play</span>
                </span>
              </a>
            </div>
          </Link>
          */}
        </div>



      </div>
    </div>
  );
}
