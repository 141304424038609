import React, { useEffect, useState } from 'react';
import Noticias from "./Noticias";
import Videos from "./Videos";
import LayoutBanner from "./LayoutBanner";
import { getDatabase, ref, onValue } from 'firebase/database';
import firebaseApp, { KEY_REF } from './adm/admin/firebaseConfig';
import Novidades from './Novidades';
import { Link } from 'react-router-dom';
import Meio from './Meio';
import BannerRH from './BannerRH';
import GoTopo from './GoTopo';
import { format } from 'date-fns';
import SalaEmpreendedor from './Transparencia';


function removePTags(html) {

  return html.replace(/<p>/g, '').replace(/<\/p>/g, '').replace(/<ol>.*?<\/ol>/gs, '').replace(/<li>.*?<\/li>/gs, '');
}

export default function Inicio() {
  const [noticias, setNoticias] = useState([]);

  useEffect(() => {
    const databaseInstance = getDatabase(firebaseApp);
    const noticiasRef = ref(databaseInstance, `${KEY_REF}/noticias`);

    onValue(noticiasRef, (snapshot) => {
      const data = snapshot.val();

      if (data) {
        const noticiasArray = Object.keys(data).map((key) => ({
          id: key,
          data: data[key].data,
          titulo: data[key].titulo,
          descricao: data[key].descricao,
          imagem: data[key].imagem,
        }));

        const noticiasOrdenadas = noticiasArray.sort((a, b) => new Date(b.data) - new Date(a.data));

        setNoticias(noticiasOrdenadas);
      }
    });
  }, []);

  const renderNoticias = (noticia) => {
    const truncatedDescricao = noticia.descricao.length > 50
      ? noticia.descricao.slice(0, 55) + "..."
      : noticia.descricao;

    const tituloParaURL = noticia.titulo.replace(/\s+/g, '-').toLowerCase();
    const dataFormatada = format(new Date(noticia.data), 'dd/MM/yyyy');

    const descricaoSemPTags = removePTags(truncatedDescricao);

    return (
      <div class="col-md-6" key={noticia.id}>
        <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
          <div class="col p-4 d-flex flex-column position-static">
            <strong class="d-inline-block mb-2 text-primary-emphasis">NOTICIAS</strong>
            <h5 class="mb-0">{noticia.titulo}</h5>
            <div className="mb-1 text-body-secondary">{dataFormatada}</div>

            <p class="card-text mb-auto">{descricaoSemPTags}</p>
            <a href={`/noticias/${tituloParaURL}/${noticia.id}`} rel="noopener noreferrer" class="icon-link gap-1 icon-link-hover stretched-link">
              Continue Lendo
              <svg class="bi"></svg>
            </a>
          </div>
          <div className="col-auto d-none d-lg-block">
            <img
              src={noticia.imagem}
              alt={noticia.titulo}
              className="bd-placeholder-img zoomed-image"
              width="200"
              height="250"
              role="img"
              aria-label="Placeholder: Thumbnail"
              focusable="false"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderUltimasNoticias = () => {
    const ultimasNoticias = [...noticias]
      .sort(() => Math.random() - 0.5)
      .slice(0, 6);
    
    return ultimasNoticias.map(renderNoticias);
  };
  
  

  const serviceData = [
    {
      icon: './imagens/icons/cheque.png',
      name: 'Contra-cheque',
      href: '/rh/cc',
    },
    {
      icon: './imagens/icons/paulogustavo.jpg',
      name: 'Lei Paulo Gustavo',
      href: '/leipaulogustavo',
    },
    {
      icon: './imagens/icons/publicacao.png',
      name: 'Decretos',
      href: '/legislacao/decretos',
    },
    {
      icon: './imagens/icons/leis.png',
      name: 'Leis Municipais',
      href: '/legislacao/leismunicipais',
    },
    {
      icon: './imagens/icons/portarias.png',
      name: 'Portarias',
      href: '/legislacao/portarias',
    },
    {
      icon: './imagens/icons/transparencia.png',
      name: 'Transparência',
      href: '/transparencia',
    },
    {
      icon: './imagens/icons/diario.png',
      name: 'Diario Oficial',
      href: '/legislacao/diariosoficial',
    },
    {
      icon: './imagens/icons/iptu.png',
      name: 'IPTU 2022',
      href: '/app/iptu',
    },
    {
      icon: './imagens/icons/empreendedor.png',
      name: 'Sala do Empreendedor',
      href: '/saladoempreendedor',
    },
    {
      icon: './imagens/icons/esic.png',
      name: 'E-SIC',
      href: '#',
    },
    {
      icon: './imagens/icons/documentos.png',
      name: 'Editais',
      href: '/arquivos/editais',
    },
    {
      icon: './imagens/icons/ctributo.png',
      name: 'Codigo Tributario',
      href: '/legislacao/codigotributario',
    },




  ];

  return (
    <>

      <Noticias />

      <Videos />
      <LayoutBanner />

      <main class="container" >
        <div class="row mb-2">

          {renderUltimasNoticias()}


        </div>
      </main>

{/* 
      <div class="container" style={{marginTop: 140}}>
        <div className="service-grid2">

          {serviceData.map((service, index) => (
            <Link style={{ textDecoration: "none" }} to={service.href} >
              <div className="service-card2" >

                <a><img src={service.icon} alt={service.name} />
                  <p>{service.name}</p></a>

              </div>
            </Link>

          ))}

        </div>
      </div>

      <section class="marginPD">
                <div class="">
                    <div class="row py-3">
                        <div class="col-12">
                            <h4 class="mb-0">Receitas e Despesas</h4>
                            <p class="mb-0">Lei Nº 12.527 (Acesso a Informação) - Lei Complementar Nº 131 (Transparência)</p>
                        </div>
                    </div>
                    <div class="row row-cols-1 row-cols-sm-1 row-cols-md-3 py-3">
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div itemscope itemtype="https://turmalina.tcepb.tc.br/documentation/budgetRevenueSource" class="card-body">
                                    <a href="https://transparencia.elmartecnologia.com.br/Contab/Receitas/Prevista?Tab=1&isModal=false&ctx=201084" class="stretched-link bs-receitas-orcamentarias-link text-decoration-none">
                                        <i class="fas fa-plus-square fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Receita Orçamentária</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div  itemscope itemtype="https://turmalina.tcepb.tc.br/documentation/extraBudgetRevenueID" class="card-body">
                                    <a href="https://transparencia.elmartecnologia.com.br/Contab/Receitas/Extra?Tab=3&isModal=false&ctx=201084" class="stretched-link bs-receitas-extraorcamentarias-link text-decoration-none">
                                        <i class="fas fa-plus-square fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Receita Extraorçamentária</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div  itemscope itemtype="https://turmalina.tcepb.tc.br/documentation/budgetExpenditureProgram" class="card-body">
                                    <a href="https://transparencia.elmartecnologia.com.br/Contab/Despesas/Fixada?Tab=1&isModal=false&ctx=201084" class="stretched-link bs-despesas-orcamentarias-link text-decoration-none">
                                        <i class="fas fa-minus-square fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Despesa Orçamentária</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div itemscope itemtype="https://turmalina.tcepb.tc.br/documentation/extraBudgetExpenditureDescription" class="card-body">
                                    <a href="https://transparencia.elmartecnologia.com.br/Contab/Despesas/Extra?Tab=4&isModal=false&ctx=201084" class="stretched-link bs-despesas-extraorcamentarias-link text-decoration-none">
                                        <i class="fas fa-minus-square fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Despesa Extraorçamentária</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div itemscope itemtype="https://turmalina.tcepb.tc.br/documentation/PaymentDocument" class="card-body">
                                    <a href="https://transparencia.elmartecnologia.com.br/Contab/Despesas/Pagamentos?Tab=6&isModal=false&ctx=201084" class="stretched-link bs-documentos-de-pagamento-link text-decoration-none">
                                        <i class="fas fa-plus-square fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Documento de Pagamento</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section class="marginPD">
                <div class="">
                    <div class="row py-3">
                        <div class="col-12">
                            <h4 class="mb-0">Atos, publicações, pessoal, compras e convênios</h4>
                            <p class="mb-0">Lei Nº 12.527 (Acesso a Informação)</p>
                        </div>
                    </div>
                    <div class="row row-cols-1 row-cols-sm-1 row-cols-md-4 py-3">
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="#" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-file-alt fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Instrumentos de Planejamento</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <Link to={"/legislacao/leismunicipais"}>
                                        <a class="stretched-link bs--link text-decoration-none">
                                            <i class="fas fa-balance-scale fs-3 d-block mb-3"></i>
                                            <h3 class="h6 mb-0">Leis Municipais</h3>
                                        </a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <Link to={"/legislacao/decretos"}>
                                        <a class="stretched-link bs--link text-decoration-none">
                                            <i class="fas fa-file-alt fs-3 d-block mb-3"></i>
                                            <h3 class="h6 mb-0">Decretos</h3>
                                        </a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="#" class="stretched-link bs-despesas-com-pessoal-link text-decoration-none">
                                        <i class="fas fa-minus-square fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Subvenções Sociais</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="#" class="stretched-link bs-despesas-com-pessoal-link text-decoration-none">
                                        <i class="fas fa-minus-square fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Emendas Impositivas</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div itemscope itemtype="https://turmalina.tcepb.tc.br/documentation/EmployeeInformation" class="card-body">
                                    <a href="https://sistema.grupofbrito.com.br/quadropessoal/?Ini=PMGJ" class="stretched-link bs-despesas-com-pessoal-link text-decoration-none">
                                        <i class="fas fa-minus-square fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Pessoal</h3>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body" itemscope itemtype="https://turmalina.tcepb.tc.br/documentation/Bidding">
                                    <a href="https://transparencia.elmartecnologia.com.br/Licitacao?Tab=1&isModal=false&ctx=201084" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-gavel fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Licitações</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <Link to={"/legislacao/diariosoficial"}>
                                        <a class="stretched-link bs--link text-decoration-none">
                                            <i class="fas fa-file-alt fs-3 d-block mb-3"></i>
                                            <h3 class="h6 mb-0">Publicações</h3>
                                        </a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="https://transparencia.elmartecnologia.com.br/Licitacao/Index/Contratos?Tab=2&isModal=false&ctx=201084" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-briefcase fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Contratos licitatórios</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div itemscope itemtype="https://turmalina.tcepb.tc.br/documentation/Contract" class="card-body">
                                    <Link to={"/arquivos/contratos"}>
                                        <a class="stretched-link bs--link text-decoration-none">
                                            <i class="fas fa-file-alt fs-3 d-block mb-3"></i>
                                            <h3 class="h6 mb-0">Contratos</h3>
                                        </a>
                                    </Link>

                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div itemscope itemtype="https://turmalina.tcepb.tc.br/documentation/Agreement" class="card-body">
                                    <a href="https://portaldatransparencia.gov.br/convenios/consulta?paginacaoSimples=true&tamanhoPagina=&offset=&direcaoOrdenacao=asc&periodoLiberacaoRecursosDe=01%2F08%2F2023&periodoLiberacaoRecursosAte=31%2F08%2F2023&uf=PB&nomeMunicipio=gurjão&colunasSelecionadas=linkDetalhamento%2CnumeroConvenio%2Cuf%2CmunicipioConvenente%2Csituacao%2CtipoTransferencia%2Cobjetivo%2CorgaoSuperior%2Corgao%2Cconcedente%2Cconvenente%2CdataInicioVigencia%2CdataFimVigencia%2CvalorLiberado%2CvalorCelebrado" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-handshake fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Convênios Federal</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="https://portaldatransparencia.gov.br/transferencias/consulta?paginacaoSimples=true&tamanhoPagina=&offset=&direcaoOrdenacao=asc&uf=PB&nomeMunicipio=GURJÃO&colunasSelecionadas=linkDetalhamento%2Cuf%2Cmunicipio%2Ctipo%2CtipoFavorecido%2Cacao%2ClinguagemCidada%2CgrupoDespesa%2CelementoDespesa%2CmodalidadeDespesa%2Cvalor&ordenarPor=mesAno&direcao=desc" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-building fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Recursos Federais</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="#" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-list-alt fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">Estagiários</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="https://transparencia.elmartecnologia.com.br/Contab/Despesas/Empenhos?Tab=2&isModal=false&hTab=1%2C4%2C5&Filter=COVID19&ctx=201084" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-heartbeat fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">COVID-19</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="/arquivos/frota" class="stretched-link bs--link text-decoration-none">
                                        <i class="fa fa-car fs-3 d-block mb-3" aria-hidden="true"></i>
                                        <h3 class="h6 mb-0">Frota Municipal</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="marginPD" >

                <div itemscope itemtype="https://turmalina.tcepb.tc.br/documentation/PlanningInstrument">
                    <div class="row py-3">
                        <div class="col-12">
                            <h4 class="mb-0">Regulamentações</h4>
                            <p class="mb-0">Normas, Regulamentações, Pareceres, Planos e Informativos</p>
                        </div>
                    </div>
                    <div class="row row-cols-1 row-cols-sm-1 row-cols-md-4 py-3">
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="https://www.gurjao.pb.gov.br/arquivos/rreo" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-file fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">RREO</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="https://www.gurjao.pb.gov.br/arquivos/rgf" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-file fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">RGF</h3>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="https://www.gurjao.pb.gov.br/arquivos/loa" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-file fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">LOA</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover card-hover-primary rounded-5 mb-3">
                                <div class="card-body">
                                    <a href="https://www.gurjao.pb.gov.br/arquivos/ldo" class="stretched-link bs--link text-decoration-none">
                                        <i class="fas fa-file fs-3 d-block mb-3"></i>
                                        <h3 class="h6 mb-0">LDO</h3>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


            </section> */}

        

      <div className="text-center" style={{ marginTop: 20, marginBottom: 20, marginLeft: 5, marginRight: 5 }}>

        <Link to={"/arquivos/farmaciabasica"}>
          <img
            src="/imagens/farmacia.png"
            className="img-fluid"
            alt="Banner"
            style={{ width: "1250px", height: "auto", borderRadius: 5 }}
          />
        </Link>

      </div>



      <Novidades />
      {/*  <BannerRH />*/}
      <GoTopo />
    </>
  );
}
