import React, { useState } from 'react';


export default function Administracao() {

    const [secretario] = useState({
        nome: 'Adriana de Oliveira Ramos',
        descricao: "O Secretário de Administração e Planejamento Municipal desempenha um papel vital na gestão pública. Suas responsabilidades incluem a coordenação e implementação de políticas administrativas, o desenvolvimento de planos estratégicos para o município, a gestão de recursos humanos, a supervisão de processos licitatórios e contratos, além do gerenciamento eficiente de patrimônio e infraestrutura. O secretário também colabora na elaboração do orçamento municipal, buscando a otimização de recursos e a promoção da eficiência operacional. Sua atuação visa assegurar a eficácia administrativa, a transparência nas ações governamentais e o alinhamento dos projetos municipais com as necessidades da comunidade.",
        foto: '/imagens/adriana.jpeg',
      });
    

  return (
    <div className="container my-4 mx-auto" style={{ maxWidth: '1600px' }}>
    <div className="row justify-content-center">
      <div className="col-md-6">
        <div className="card">
          <div className="row">
            <div className="col-md-6">
              <img
                src={secretario.foto}
                alt={secretario.nome}
                className="card-img-top img-fluid d-none d-md-block"
                style={{ margin:"5%", maxWidth: '500px' }}
              />

              <div style={{margin:"5%", marginLeft:"9%"}}>
              <button onClick="" type="button" class="btn btn-sm btn-outline-secondary d-none d-md-inline">
      <i class="fa-solid fa-square-envelope"></i> E-MAIL
    </button>
    
    <button onClick="" type="button" class="btn btn-sm btn-outline-secondary d-none d-md-inline">
      <i class="fa-solid fa-phone" style={{ color: "#27b300" }}></i> TELEFONE
    </button>
    <button onClick="" type="button" class="btn btn-sm btn-outline-secondary d-none d-md-inline">
      <i class="fa-solid fa-clock"></i> HORÁRIOS
    </button>
              </div>
      
            </div>
            <div className="col-md-6">
  <div className="card-body">
    <h3 className="d-none d-md-block card-title">{secretario.nome}</h3>
    <p className="d-none d-md-block card-text text-justify" style={{padding: "1%"}}>{secretario.descricao}</p>
   
    
  </div>
</div>

          </div>
          <div className="d-md-none text-center">
            <img
              src={secretario.foto}
              alt={secretario.nome}
              className="card-img-top img-fluid"
              style={{ maxWidth: '300px' }}
            />
            <div className="text-center mt-3" style={{marginBottom: "10px"}}>
              <h3 className="card-title">{secretario.nome}</h3>
              <p className="card-text text-justify " style={{padding: "5%"}}>{secretario.descricao}</p>
   

              <button onClick="" type="button" class="btn btn-sm btn-outline-secondary"><i class="fa-solid fa-square-envelope"></i> E-MAIL</button>
                <button onClick="" type="button" class="btn btn-sm btn-outline-secondary"><i class="fa-solid fa-phone" style={{color: "#27b300"}}></i> TELEFONE </button>
                <button onClick="" type="button" class="btn btn-sm btn-outline-secondary"><i class="fa-solid fa-clock"></i> HORARIOS</button>

          </div>
            </div>
        
        </div>
      </div>
    </div>
  </div>
  
  
  
  );
}
