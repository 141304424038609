import React, { useEffect, useState } from 'react';
import { compareAsc, parseISO } from 'date-fns';
import './css/NewsGrid.css'; 
import { Link } from 'react-router-dom';
import { getDatabase, ref, onValue } from 'firebase/database';
import firebaseApp from './adm/admin/firebaseConfig'; 



export default function GoTopo() {
  

  return (

    <div class="col-lg-2 col-md-2 mx-auto">
        <a href="#" class="btn btn-primary my-2"><i class="fa-solid fa-circle-up fa-beat-fade"></i> Voltar ao topo</a> 
        <br/>  <br/>
        </div>

  );
}
