import React from 'react'
import './pages_documentos.css';

export default function Requerimentos() {
	return (

		<main>

			<section class="ftco-section">
				<div class="container">
					<div class="row justify-content-center">
						<div class="col-md-6 text-center mb-5">
							<br /> <br />
							<div class="text-with-gradient">
								<div class="gradient-line"></div>
								<div class="text-container">
									<span> REQUERIMENTOS</span>
								</div>
								<div class="gradient-line"></div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="table-wrap">
								<table class="table">
									<thead class="thead-primary">
										<tr>

											<th>Nome</th>

											<th>Arquivo Baixável</th>
											<th>Data Publicação</th>
										</tr>
									</thead>
									<tbody>
										<tr>

											<td>REQUERIMENTO DE FERIAS</td>

											<td><a href="/documentos/rh/Requerimento Ferias.docx">Download</a></td>
											<td>11/11/2023</td>
										</tr>
										<tr>

											<td>LICENÇA MATERNIDADE</td>

											<td><a href="/documentos/rh/Requerimento Licença Maternidade.docx">Download</a></td>
											<td>11/11/2023</td>
										</tr>
										<tr>

											<td>LICENÇA PATERNIDADE</td>

											<td><a href="/documentos/rh/Requerimento Licença Paternidade.docx">Download</a></td>
											<td>11/11/2023</td>
										</tr>
										<tr>

											<td>LICENÇA SEM VENCIMENTO</td>

											<td><a href="/documentos/rh/Requerimento Licença Sem Vencimento.docx">Download</a></td>
											<td>11/11/2023</td>
										</tr>
										<tr>

											<td>LICENÇA PRÊMIO</td>

											<td><a href="/documentos/rh/Requerimento Licença Prêmio.docx">Download</a></td>
											<td>11/11/2023</td>
										</tr>
										<tr>

											<td>SALARIO FAMILIA</td>

											<td><a href="#">Download</a></td>
											<td>11/11/2023</td>
										</tr>


									</tbody>

								</table>
								<br /> <br />
							</div>
						</div>
					</div>
					<br /> <br /> <br /> <br />
				</div>
			</section>

		</main>

	)
}
