import React, { useEffect, useState } from 'react';
import { compareAsc, parseISO } from 'date-fns';
import './css/NewsGrid.css'; 
import { Link } from 'react-router-dom';
import { getDatabase, ref, onValue } from 'firebase/database';
import firebaseApp, { KEY_REF } from './adm/admin/firebaseConfig'; 



function removePTags(html) {

  return html.replace(/<p>/g, '').replace(/<\/p>/g, '').replace(/<ol>.*?<\/ol>/gs, '').replace(/<li>.*?<\/li>/gs, '');
}


export default function NoticiasApp() {
  const [noticias, setNoticias] = useState([]);
  const [isMouseOver, setIsMouseOver] = useState(false);

  const handleMouseEnter = () => {
    setIsMouseOver(true);
  };

  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };

  useEffect(() => {
    const databaseInstance = getDatabase(firebaseApp);
    const noticiasRef = ref(databaseInstance, `${KEY_REF}/noticias`);
  
    onValue(noticiasRef, (snapshot) => {
      const data = snapshot.val();
  
      if (data) {
        const noticiasArray = Object.keys(data).map((key) => ({
          id: key,
          titulo: data[key].titulo,
          descricao: data[key].descricao,
          imagem: data[key].imagem,
          data: data[key].data, 
        }));
  
      
        const noticiasOrdenadas = noticiasArray.sort((a, b) => new Date(b.data) - new Date(a.data));
  
        setNoticias(noticiasOrdenadas);
      }
    });
  }, []);
  

  const ordenarNoticiasPorData = (noticias) => {
    return noticias.sort((a, b) => compareAsc(parseISO(b.data), parseISO(a.data)));
  };

 const renderNoticias = (noticia, index) => {
  const truncatedDescricao = noticia.descricao.length > 50
    ? noticia.descricao.slice(0, 55) + "..."
    : noticia.descricao;

  const descricaoSemPTags = removePTags(truncatedDescricao);

  const tituloParaURL = noticia.titulo
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/\s+/g, '-')
    .toLowerCase();

    const isLargeNews = index === 0;
    const isTwoColumns = index === 1; 
    const isNormal = index > 1; 
      

    const descricaoNoticia = isTwoColumns ? noticia.descricao.slice(0, 210) + "..." : truncatedDescricao;


    return (
      <div
      className={`news-card ${isLargeNews ? 'large-news' : ''} ${isTwoColumns ? 'two-columns' : ''} ${isNormal ? 'normal' : ''} ${isMouseOver ? 'hovered' : ''}`}
      key={noticia.id}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <a href={`/noticias/${tituloParaURL}/${noticia.id}`} target="_blank" rel="noopener noreferrer">
        {isLargeNews && isMouseOver && (
          <div className="new-label">NOVO!</div>
        )}
        <img src={noticia.imagem} alt={noticia.titulo} />
        <h2>{noticia.titulo}</h2>
        <p>{removePTags(descricaoNoticia)}</p>
        {isTwoColumns && (
          <button type="button" className="btn btn-primary">LEIA MAIS</button>
        )}
      </a>
    </div>
    );
};

const renderUltimasNoticias = () => {
 
  const noticiasOrdenadas = ordenarNoticiasPorData(noticias);
 
  const ultimasNoticias = noticiasOrdenadas.slice(0, 5);
  
  return ultimasNoticias.map(renderNoticias);
};

  const renderEstatico = (noticia) => {
    const truncatedDescricao = noticia.descricao.length > 50
      ? noticia.descricao.slice(0, 55) + "..."
      : noticia.descricao;

    const descricaoSemPTags = removePTags(truncatedDescricao);

    return (
      <div className="news-card" key={noticia.id}>
        <a href="#">
          <img src={noticia.imagem} alt={noticia.titulo} />
          <h2>{noticia.titulo}</h2>
          <p>{descricaoSemPTags}</p>
          </a>
      </div>
    );
  };
  const hasNoticias = noticias && noticias.length > 0;


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  

  const serviceData = [
    {
      icon: '../imagens/icons/cheque.png',
      name: 'Contra-cheque',
      href: '/rh/cc',
    },
    {
      icon: './imagens/icons/paulogustavo.jpg',
      name: 'Lei Paulo Gustavo',
      href: '/leipaulogustavo',
    },
    {
        icon: './imagens/icons/publicacao.png',
        name: 'Decretos',
        href: '/legislacao/decretos',
      },
      {
        icon: './imagens/icons/leis.png',
        name: 'Leis Municipais',
        href: '/legislacao/leismunicipais',
      },
      {
        icon: './imagens/icons/portarias.png',
        name: 'Portarias',
        href: '/legislacao/portarias',
      },
      {
        icon: './imagens/icons/transparencia.png',
        name: 'Transparência',
        href: '/transparencia',
      },
      {
        icon: './imagens/icons/diario.png',
        name: 'Diario Oficial',
        href: '/legislacao/diariosoficial',
      },
      {
        icon: './imagens/icons/iptu.png',
        name: 'IPTU 2022',
        href: '/app/iptu',
      },
      {
        icon: './imagens/icons/empreendedor.png',
        name: 'Sala do Empreendedor',
        href: '/saladoempreendedor',
      },
      {
        icon: './imagens/icons/esic.png',
        name: 'E-SIC',
        href: '#',
      },
      
      
   
  ];

  const noticiasEstatico = [
    {
      
      titulo: 'Carregando Noticia...',
      descricao: 'Carregando Descrição...',
      imagem: 'http://sulmak.com.br/wp-content/uploads/2016/12/1285541001-gif-carregando-cocacolazerrooo.gif',
    },
    {
      
      titulo: 'Carregando Noticia...',
      descricao: 'Carregando Descrição...',
      imagem: 'http://sulmak.com.br/wp-content/uploads/2016/12/1285541001-gif-carregando-cocacolazerrooo.gif',
    },
    {
      
      titulo: 'Carregando Noticia...',
      descricao: 'Carregando Descrição...',
      imagem: 'http://sulmak.com.br/wp-content/uploads/2016/12/1285541001-gif-carregando-cocacolazerrooo.gif',
    },
    {
      
      titulo: 'Carregando Noticia...',
      descricao: 'Carregando Descrição...',
      imagem: 'http://sulmak.com.br/wp-content/uploads/2016/12/1285541001-gif-carregando-cocacolazerrooo.gif',
    },
    {
      
      titulo: 'Carregando Noticia...',
      descricao: 'Carregando Descrição...',
      imagem: 'http://sulmak.com.br/wp-content/uploads/2016/12/1285541001-gif-carregando-cocacolazerrooo.gif',
    },
    {
      
      titulo: 'Carregando Noticia...',
      descricao: 'Carregando Descrição...',
      imagem: 'http://sulmak.com.br/wp-content/uploads/2016/12/1285541001-gif-carregando-cocacolazerrooo.gif',
    },
    
  ];


  return (

  
    <div className="news-grid-container2">
  

    <div>

    <div class="acessorapido"><span>ACESSO RÁPIDO</span></div>
  
  <div className="service-grid">
    {serviceData.map((service, index) => (
      <Link style={{textDecoration:"none"}} to={service.href} >
      <div className="service-card" > 
     
      <a><img src={service.icon} alt={service.name} />
          <p>{service.name}</p></a>
          
      </div></Link>
    ))}
  </div>

  </div>

  
  
  </div>
  );
}
