import React from 'react'
import './css/pages_documentos.css';

export default function LeiPauloGustavo() {
	return (

		<main>

			<section class="ftco-section">
				<div class="container">
					<div class="row justify-content-center">
						<div class="col-md-6 text-center mb-5">
							<br /> <br />
							<div class="text-with-gradient">
								<div class="gradient-line"></div>
								<div class="text-container">
									<span>LEI PAULO GUSTAVO</span>
								</div>
								<div class="gradient-line"></div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="table-wrap">
								<table class="table">
									<thead class="thead-primary">
										<tr>
											<th>#</th>
											<th>Nome</th>

											<th>Arquivo Baixável</th>
											<th>Data Publicação</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<th scope="row">1</th>
											<td>Publicação do Edital N° 001 02 e 03-2023 Audiovisual Lei Paulo Gustavo Corrigido - 07-12-2023</td>

											<td><a href="/documentos/diariooficial/Publicação do Edital N° 001 02 e 03-2023 Audiovisual Lei Paulo Gustavo Corrigido - 07-12-2023.pdf">Download</a></td>
											<td>07/12/2023</td>
										</tr>
										<tr>
											<th scope="row">1</th>
											<td>Publicação dos Editais N° 001-2023  e  Premio Produção Nº 002-2023 e Edital de Chamanento Publico  003-2023</td>

											<td><a href="/documentos/Publicação dos Editais   N° 001-2023  e  Premio Produção Nº 002-2023 e Edital de Chamanento Publico  003-2023- 07-11-2023.pdf">Download</a></td>
											<td>23/11/2023</td>
										</tr>
										<tr>
											<th scope="row">2</th>
											<td>ANEXO III - Plano de Trabalho e Planilha Orçamentaria</td>

											<td><a href="/documentos/ANEXO III Plano de Trabalho e Planilha Orçamentaria EDITAL 001.pdf">Download</a></td>
											<td>07/11/2023</td>
										</tr>
										<tr>
											<th scope="row">3</th>
											<td>EDITAL 003 - INSTRUTORES EM AUDIOVISUAL</td>

											<td><a href="/documentos/EDITAL 003 CHAMAMENTO PUBLICO DE INSTRUTORES EM AUDIOVISUAL.pdf">Download</a></td>
											<td>07/11/2023</td>
										</tr>
										<tr>
											<th scope="row">4</th>
											<td>FORMULÁRIO - EDITAL Nº 003 - INSTRUTORES EM AUDIOVISUAL</td>

											<td><a href="/documentos/FORMULÁRIO DE INSCRIÇÃO EDITAL Nº 003 CHAMAMENTO PUBLICO INSTRUTORES EM AUDIOVISUAL.pdf">Download</a></td>
											<td>07/11/2023</td>
										</tr>

									</tbody>

								</table>
								<br /> <br />
							</div>
						</div>
					</div>
					<br /> <br /> <br /> <br />
				</div>
			</section>

		</main>

	)
}