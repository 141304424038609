import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faRobot } from '@fortawesome/free-solid-svg-icons';
import { getDatabase, ref, onValue, update, set, push } from 'firebase/database';
import firebaseApp from '../adm/admin/firebaseConfig'; 

const Contato = () => {
    const faqData = [
        {
          question: 'Como posso obter uma segunda via do meu IPTU?',
          answer: 'Você pode obter a segunda via do seu IPTU através do site da prefeitura ou pessoalmente no setor de atendimento ao contribuinte.',
          link: 'https://gurjao.pb.gov.br/app/iptu',
        },
        {
          question: 'Qual é o horário de funcionamento da prefeitura?',
          answer: 'O horário de funcionamento da prefeitura é das 8h às 14h, de segunda a sexta-feira.',
          link: null,
        },
        
             
      ];
      

  const [userInfo, setUserInfo] = useState({
    nomeCompleto: '',
    telefone: '',
    categoria: '',
  });

  const [chatMessages, setChatMessages] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [userInput, setUserInput] = useState('');
  const [isBotTyping, setIsBotTyping] = useState(false);
  const [exibirBotaoLink, setExibirBotaoLink] = useState(false);
  const [linkSelecionado, setLinkSelecionado] = useState(null);
  const [showForm, setShowForm] = useState(true);

  const handleFormSubmit = () => {
    if (userInfo.nomeCompleto.trim() !== '' && userInfo.telefone.trim() !== '') {
      const db = getDatabase(firebaseApp);
      const chatSaveRef = ref(db, 'chatsave');

     const userData = {
        
        nomeCompleto: userInfo.nomeCompleto,
        telefone: userInfo.telefone,
      };

      push(chatSaveRef, userData);
      
      setChatMessages([{ text: 'Olá! Como posso ajudar?', user: 'bot' }]);
  
      setShowForm(false);
      
      setChatMessages([{ text: 'Olá! Como posso ajudar?', user: 'bot' }]);
    } else {
      alert('Por favor, forneça seu nome completo e telefone.');
    }
  };
  
  

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleUserQuestion();
    }
  };

  const handleUserInput = (event) => {
    setUserInput(event.target.value);
  };

 
  const handleUserQuestion = () => {
    if (userInput.trim() === '') {
      return;
    }

    const userQuestion = userInput.trim().toLowerCase();

    setIsBotTyping(true);

    const normalizedFAQ = faqData.map((faq) => ({
      ...faq,
      normalizedQuestion: faq.question.toLowerCase(),
      normalizedAnswer: faq.answer.toLowerCase(),
    }));

    const matchingFAQ = normalizedFAQ.find((faq) => {
      const keywords = faq.normalizedQuestion.split(/\s+/);
      const keywordRegex = new RegExp(keywords.join('|'));

      return keywordRegex.test(userQuestion);
    });

    const newMessagesWithUserQuestion = [
      ...chatMessages,
      { text: `Você: ${userInput}`, user: 'user' },
    ];

    setChatMessages(newMessagesWithUserQuestion);

    setTimeout(() => {
      if (matchingFAQ) {
        const newMessagesWithMatchingAnswer = [
          ...newMessagesWithUserQuestion,
          { text: `Digitando...`, user: 'bot' },
        ];

        setChatMessages(newMessagesWithMatchingAnswer);

        setTimeout(() => {
          const newMessagesWithMatchingAnswerComplete = [
            ...newMessagesWithMatchingAnswer,
            { text: `${matchingFAQ.answer}`, user: 'bot' },
          ];

          setChatMessages(newMessagesWithMatchingAnswerComplete);

         setIsBotTyping(false);
        }, 2000);
      } else {
        const newMessagesWithNoAnswer = [
          ...newMessagesWithUserQuestion,
          { text: 'Digitando...', user: 'bot' },
        ];

        setChatMessages(newMessagesWithNoAnswer);

        setTimeout(() => {
          const newMessagesWithNoAnswerComplete = [
            ...newMessagesWithNoAnswer,
            { text: 'Desculpe, não tenho uma resposta para essa pergunta.', user: 'bot' },
          ];

          setChatMessages(newMessagesWithNoAnswerComplete);

        
          setIsBotTyping(false);
        }, 2000);
      }
    }, 100);

    setUserInput('');
  };

  useEffect(() => {
    setTimeout(() => {
      setChatMessages([{ text: 'Olá! Como posso ajudar?', user: 'bot' }]);
    }, 1000);
  }, []);

  

  const handleQuestionClick = (index) => {
    const selectedFaq = faqData[index];

    const newMessagesWithQuestion = [
      ...chatMessages,
      { text: selectedFaq.question, user: 'user' },
    ];

    setChatMessages(newMessagesWithQuestion);
    setSelectedQuestion(index);

    setTimeout(() => {
        const newMessagesWithMatchingAnswer = [
           ...newMessagesWithQuestion,
            { text: `Digitando...`, user: 'bot' },
          ];
  
          setChatMessages(newMessagesWithMatchingAnswer);
  
        setTimeout(() => {
        
            const newMessagesWithAnswer = [
              ...newMessagesWithQuestion,
              { text: selectedFaq.answer, user: 'bot' },
            ];
      
            setChatMessages(newMessagesWithAnswer);
          }, 1500);

    }, 100);
   
  
  };

  const styles = {
    container: {
      padding: '20px',
      backgroundColor: '#ffffff',
      maxWidth: '450px',
      margin: 'auto',
    },
    header: {
      color: '#333333',
      marginTop: '10px',
      marginBottom: '10px',
      textAlign: "center"
    },
    chatContainer: {
      height: '300px',     
      border: '1px solid #ccc',
      overflowY: 'scroll',
      marginBottom: '10px',
      padding: '10px',
      backgroundColor: '#f0f0f0',
    },
    chatMessage: {
      marginBottom: '10px',
    },
    userIcon: {
      marginRight: '5px',
      color: 'green',
    },
    botIcon: {
      marginRight: '5px',
      color: 'blue',
    },
    faqItem: {
      cursor: 'pointer',
      marginBottom: '10px',
      padding: '10px',
      borderRadius: '5px',
    },
    userInput: {
      width: 'calc(100% - 20px)',
      padding: '15px',
      marginBottom: '10px',
      borderRadius: '10px',
      margin: '5px',
    },
    askButton: {
      width: '100%',
      padding: '10px',
      backgroundColor: 'green',
      color: '#ffffff',
      borderRadius: '5px',
      cursor: 'pointer',
    },
    formContainer: {
      padding: '20px',
      backgroundColor: '#ffffff',
      maxWidth: '450px',
      margin: 'auto',
    },
    formHeader: {
      color: '#333333',
      marginTop: '10px',
      marginBottom: '10px',
      textAlign: 'center',
      fontSize: "24px"
    },
    formInput: {
      width: 'calc(100% - 20px)',
      padding: '15px',
      marginBottom: '10px',
      borderRadius: '10px',
      margin: '5px',
    },
    formButton: {
      width: '100%',
      padding: '10px',
      marginTop: "15px",
      backgroundColor: 'green',
      color: '#ffffff',
      borderRadius: '5px',
      cursor: 'pointer',
    },
    
  };

  return (
    
    <div style={showForm ? styles.formContainer : styles.container}>
    {showForm ? (
      <div>
        <h2 style={styles.formHeader}>Informe seus dados</h2>
        <div>
          <label>Nome Completo:</label>
          <input
            style={styles.formInput}
            type="text"
            placeholder="Seu Nome Completo"
            value={userInfo.nomeCompleto}
            onChange={(e) => setUserInfo({ ...userInfo, nomeCompleto: e.target.value })}
          />
        </div>
        <div>
          <label>Telefone:</label>
          <input
            style={styles.formInput}
            type="text"
            placeholder="Seu Telefone"
            value={userInfo.telefone}
            onChange={(e) => setUserInfo({ ...userInfo, telefone: e.target.value })}
          />
        </div>
        <button style={styles.formButton} onClick={handleFormSubmit}>
          Enviar
        </button>
      </div>
      ) : (
        <div>
          <h2 style={styles.header}>Chat de Atendimento</h2>
          <div style={styles.chatContainer}>
            {chatMessages.map((message, index) => (
              <div key={index} style={{ ...styles.chatMessage, textAlign: message.user === 'user' ? 'right' : 'left' }}>
                <FontAwesomeIcon
                  icon={message.user === 'user' ? faUser : faRobot}
                  style={message.user === 'user' ? styles.userIcon : styles.botIcon}
                />
                <strong>{message.user === 'user' ? 'Você: ' : 'Atendente: '}</strong>
                {message.text}
              </div>
            ))}
          </div>
          <div>
            <input
              type="text"
              placeholder="Digite sua pergunta..."
              style={styles.userInput}
              value={userInput}
              onChange={handleUserInput}
              onKeyPress={handleKeyPress}
            />
            <button style={styles.askButton} onClick={handleUserQuestion}>
              {isBotTyping ? 'Enviando...' : 'Perguntar'}
            </button>
          </div>
          <div>
            <h2 style={{ ...styles.header, textAlign: 'center', fontSize: 15 }}>Perguntas Frequentes</h2>
            <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
              {faqData.map((faq, index) => (
                <li
                  key={index}
                  style={{
                    ...styles.faqItem,
                    backgroundColor: index === selectedQuestion ? '#b3d4fc' : '#e0e0e0',
                  }}
                  onClick={() => handleQuestionClick(index)}
                >
                  {faq.question}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
    
  );
};

export default Contato;
