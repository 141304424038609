import React, { useState } from 'react';
import './pages_documentos.css';

export default function FichaFuncional() {
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };

  return (
    <main>
      <section className="ftco-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 text-center mb-5">
              <br /> <br />
              <div class="text-with-gradient">
								<div class="gradient-line"></div>
								<div class="text-container">
									<span>FICHA FUNCIONAL</span>
								</div>
								<div class="gradient-line"></div>
							</div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {!iframeLoaded && (
                <div className="loading-overlay">
                  <p className="loading-text">Carregando conteúdo, por favor aguarde...</p>
                </div>
              )}
              <iframe
                className="iframe-container"
                src="https://sistema.grupofbrito.com.br/portalservidor/?Ini=PMGJ"
                style={{ border: '0px solid #ccc', width: '100%', height: '600px' }}
                title="Ficha Funcional"
                onLoad={handleIframeLoad}
              ></iframe>
              <div className="baixeoapp">
                <img className="baixe" src="/imagens/app.png" alt="Baixe o App" />
              </div>
            </div>
          </div>
          <br /> <br /> <br /> <br />
        </div>
      </section>
    </main>
  );
}
