import React, { useState } from 'react';

export default function Rh() {
  const [secretario] = useState({
    nome: 'Maria Gabriella de Farias',
    descricao: "Desenvolver e acompanhar a execução de planos e projetos referentes à gestão pública; Organizar o cadastro de servidores e toda estrutura da gestão, incluindo a determinação sobre funções e serviços; Observar e acompanhar a elaboração da folha de pagamento; Acompanhar e Promover a valorização humana e capacitações técnicas, direcionadas para os servidores. aplicação, a orientação e a fiscalização da legislação de pessoal aplicável aos servidores municipais; documentação e arquivo.",
    foto: '/imagens/gabriella.jpg',
    telefone: "(83) 98206-5380",
    email: "pmgurjao.rh@gmail.com",
    horario: "08h ás 14h - Segunda a Sexta-Feira"
  });

  const [mostrarTelefone, setMostrarTelefone] = useState(false);
  const [mostrarEmail, setMostrarEmail] = useState(false);
  const [mostrarHorario, setMostrarHorario] = useState(false);

  const handleTelefoneClick = () => {
    setMostrarTelefone(true);
    setMostrarEmail(false);
    setMostrarHorario(false);
  };

  const handleEmailClick = () => {
    setMostrarTelefone(false);
    setMostrarEmail(true);
    setMostrarHorario(false);
  };

  const handleHorarioClick = () => {
    setMostrarTelefone(false);
    setMostrarEmail(false);
    setMostrarHorario(true);
  };

  const handleFecharClick = () => {
    setMostrarTelefone(false);
    setMostrarEmail(false);
    setMostrarHorario(false);
  };

  return (
    <div className="container my-4 mx-auto" style={{ maxWidth: '1600px' }}>
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="row">
              <div className="col-md-6">
                <img
                  src={secretario.foto}
                  alt={secretario.nome}
                  className="card-img-top img-fluid d-none d-md-block"
                  style={{ margin: "5%", maxWidth: '500px' }}
                />

                <div style={{ margin: "5%", marginLeft: "9%" }}>
                  <button onClick={handleEmailClick} type="button" className="btn btn-sm btn-outline-secondary d-none d-md-inline">
                    <i className="fa-solid fa-square-envelope"></i> E-MAIL
                  </button>

                  <button onClick={handleTelefoneClick} type="button" className="btn btn-sm btn-outline-secondary d-none d-md-inline">
                    <i className="fa-solid fa-phone" style={{ color: "#27b300" }}></i> TELEFONE
                  </button>

                  <button onClick={handleHorarioClick} type="button" className="btn btn-sm btn-outline-secondary d-none d-md-inline">
                    <i className="fa-solid fa-clock"></i> HORÁRIOS
                  </button>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card-body">
                  <h3 className="d-none d-md-block card-title">{secretario.nome}</h3>
                  <h6 className="d-none d-md-block text-center">Coodernadora de Recursos Humanos</h6>
                  <p className="d-none d-md-block card-text text-justify" style={{ padding: "1%" }}>{secretario.descricao}</p>

                  {mostrarEmail && (
                    <p className="d-none d-md-block card-text text-justify" style={{ padding: "1%" }}>
                      E-mail: {secretario.email}
                    </p>
                  )}

                  {mostrarTelefone && (
                    <p className="d-none d-md-block card-text text-justify" style={{ padding: "1%" }}>
                      Telefone: {secretario.telefone}
                    </p>
                  )}

                  {mostrarHorario && (
                    <p className="d-none d-md-block card-text text-justify" style={{ padding: "1%" }}>
                      Horário: {secretario.horario}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="d-md-none text-center">
              <img
                src={secretario.foto}
                alt={secretario.nome}
                className="card-img-top img-fluid"
                style={{ maxWidth: '300px' }}
              />
              <div className="text-center mt-3">
                <h3 className="card-title">{secretario.nome}</h3>
                <h6 className="text-center">Coodernadora de Recursos Humanos</h6>
                <p className="card-text text-justify " style={{ padding: "5%" }}>{secretario.descricao}</p>

                {mostrarEmail && (
                  <p className="card-text text-justify" style={{ padding: "5%" }}>
                    E-mail: {secretario.email}
                  </p>
                )}

                {mostrarTelefone && (
                  <p className="card-text text-justify" style={{ padding: "5%" }}>
                    Telefone: {secretario.telefone}
                  </p>
                )}

                {mostrarHorario && (
                  <p className="card-text text-justify" style={{ padding: "5%" }}>
                    Horário: {secretario.horario}
                  </p>
                )}

                <button onClick={handleEmailClick} type="button" className="btn btn-sm btn-outline-secondary">
                  <i className="fa-solid fa-square-envelope"></i> E-MAIL
                </button>
                <button onClick={handleTelefoneClick} type="button" className="btn btn-sm btn-outline-secondary">
                  <i className="fa-solid fa-phone" style={{ color: "#27b300" }}></i> TELEFONE
                </button>
                <button onClick={handleHorarioClick} type="button" className="btn btn-sm btn-outline-secondary">
                  <i className="fa-solid fa-clock"></i> HORÁRIOS
                </button>
          
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
