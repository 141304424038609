import React from 'react';
import './css/videos.css';
import { Link } from 'react-router-dom';



function Videos() {

  return (



    <div style={{backgroundColor: 'white', padding: 10, paddingBottom: 50 , borderBottom: '1px solid #d3d3d3',borderTop: '1px solid #d3d3d3'}}>



  
        <div class="container" style={{paddingTop: 30}}>
          <h3 class="titulo_p"><span><i class="fa-brands fa-youtube"></i>  #GurjãoProMundo </span></h3>
          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">

            <div class="col">
              <div class="card shadow-sm">
                <iframe class="bd-placeholder-img card-img-top" width="100%" height="225" src="https://www.youtube.com/embed/CO9RR5ptjC4" title="Gurjão lança aplicativo para comerciantes locais" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <div class="card-body">
                  <p class="card-text">Entrega de uma nova Van para Saúde e Patrulhas Mecanizadas para Secretaria de Agricultura</p>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="btn-group">
                      <a href="https://www.youtube.com/embed/CO9RR5ptjC4"> <button type="button" class="btn btn-sm btn-outline-secondary">Visualizar</button></a>
                      <a href="#">   |   </a>
                      <a href='#'>
                        <button type="button" class="btn btn-sm btn-outline-secondary">Gostei</button>
                      </a>
                    </div>
                    <small class="text-body-secondary">6 min 44 seg</small>
                  </div>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card shadow-sm">
                <iframe class="bd-placeholder-img card-img-top" width="100%" height="225" src="https://www.youtube.com/embed/fzjE_OmFu1o" title="Gurjão lança aplicativo para comerciantes locais" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <div class="card-body">
                  <p class="card-text">62 Anos de Emancipação Política de Gurjão PB | JAPÃOZIN e LEIDINHA SANTOS </p>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="btn-group">
                      <a href='https://www.youtube.com/embed/fzjE_OmFu1o'> <button type="button" class="btn btn-sm btn-outline-secondary">Visualizar</button></a>
                      <a href="#">   |   </a>
                      <a href='#'>
                        <button type="button" class="btn btn-sm btn-outline-secondary">Gostei</button>
                      </a>
                    </div>
                    <small class="text-body-secondary"> 4h 56m 14s</small>
                  </div>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card shadow-sm">
                <iframe class="bd-placeholder-img card-img-top" width="100%" height="225" src="https://www.youtube.com/embed/_otSf9LodqE" title="CERIMÔNIA DE POSSE DOS NOVOS CONSELHEIROS TUTELARES 2024 - 2028" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <div class="card-body">
                  <p class="card-text">CERIMÔNIA DE POSSE DOS NOVOS CONSELHEIROS TUTELARES 2024 - 2028</p>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="btn-group">
                      <a href='https://www.youtube.com/watch?v=_otSf9LodqE'> <button type="button" class="btn btn-sm btn-outline-secondary">Visualizar</button></a>
                      <a href="#">   |   </a>
                      <a href='#'>
                        <button type="button" class="btn btn-sm btn-outline-secondary">Gostei</button>
                      </a>
                    </div>
                    <small class="text-body-secondary">21 min 27 seg</small>
                  </div>
                </div>
              </div>
            </div>




          </div>

        </div>



     

    </div>


  );
}

export default Videos;