import React, { useEffect, useState } from 'react';
import { View } from 'react';
import { getDatabase, ref, onValue, push, set, get } from 'firebase/database';
import { auth } from '../adm/admin/firebaseConfig';
import { Button } from 'react-bootstrap';

const Mensagem = ({ nomeUsuario, texto, enviadoPorUsuarioAtual }) => {
    const balaoMensagemStyle = {
        backgroundColor: enviadoPorUsuarioAtual ? '#0084FF' : '#E5E5EA',
        padding: 10,
        color: enviadoPorUsuarioAtual ? 'white' : 'black',
        marginRight: 7,
        marginBottom: 2,
        marginLeft: 8,
        borderRadius: 8,
    };

    const nomeUsuarioStyle = {
        marginLeft: 8,
        fontSize: 9,
        color: '#FFFF',
        marginBottom: 2,
        fontWeight: 'bold',
    };

    const containerStyle = {
        alignSelf: enviadoPorUsuarioAtual ? 'flex-end' : 'flex-start',
        marginBottom: 8,
    };

    return (
        <div style={containerStyle}>
            {!enviadoPorUsuarioAtual && nomeUsuario && <div style={nomeUsuarioStyle}>{nomeUsuario.toUpperCase()}</div>}
            <div style={{ alignSelf: 'flex-start' }}>
                <div style={balaoMensagemStyle}>{texto}</div>
            </div>
        </div>
    );
};

export default function Chat() {
    const [mensagens, setMensagens] = useState([]);
    const [textoMensagem, setTextoMensagem] = useState('');
    const [enviandoMensagem, setEnviandoMensagem] = useState(false);

    const flatListRef = React.useRef(null);
    const database = getDatabase();

    const MensagemMemoized = React.memo(Mensagem);

    const enviarMensagem = async () => {
        if (enviandoMensagem || textoMensagem.trim() === '') return;
    
        const user = auth.currentUser?.uid;
    
        if (!user) {
            setEnviandoMensagem(false);
            alert('Usuário não autenticado');
            return;
        }
    
        try {
            const nomeUsuario = "eu";
    
            const novaMensagemRef = push(ref(database, 'conversas/idDaConversa/mensagens'));
    
            await set(novaMensagemRef, {
                texto: textoMensagem,
                enviadoPor: user,
                nomeUsuario: nomeUsuario,
            });
    
            setTextoMensagem('');
            setEnviandoMensagem(false);
        } catch (error) {
            console.error('Erro ao enviar mensagem:', error);
            alert('Erro ao enviar mensagem');
            setEnviandoMensagem(false);
        }
    };
    

    useEffect(() => {
        const conversasRef = ref(database, 'conversas/idDaConversa/mensagens');
        const unsubscribe = onValue(conversasRef, (snapshot) => {
            const mensagensArray = [];
            snapshot.forEach((childSnapshot) => {
                const mensagem = childSnapshot.val();
                mensagensArray.push(mensagem);
            });

            setMensagens(mensagensArray);

            if (mensagensArray.length > 10) {
                setTimeout(() => {
                    flatListRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
                }, 50);
            }
        });

        return () => unsubscribe();
    }, [database]);

    return (
        <div style={{
            height: "500px",
            maxWidth: 'auto',
            marginLeft:450,
            marginRight:450,
            marginTop: 30,
            marginBottom: 30,
            position: 'relative', // Torna a posição relativa para que o position: fixed funcione corretamente
            border: '1px solid #ccc', // Adiciona borda
            borderRadius: '10px', // Borda arredondada
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', // Adiciona sombra
          }}>
            <div style={{
              overflowY: 'auto',
              maxHeight: 'calc(100% - 70px)', // Ocupa 100% da altura disponível menos o espaço do input
              padding: '10px', // Adiciona algum preenchimento ao conteúdo
            }}>
              {mensagens.map((item, index) => (
                <MensagemMemoized
                  key={index}
                  nomeUsuario={item.nomeUsuario}
                  texto={item.texto}
                  enviadoPorUsuarioAtual={item.enviadoPor === auth.currentUser?.uid}
                />
              ))}
            </div>
            <div style={{
              position: 'absolute', // Posição absoluta em relação ao componente pai
              bottom: 0, // Fixa o componente no rodapé
              width: '100%', // Ocupa 100% da largura disponível
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between', // Alinha os itens do conteúdo horizontalmente
              padding: '8px',
              backgroundColor: "#E3E3E3",
              borderBottomLeftRadius: '10px', // Borda arredondada na parte inferior esquerda
              borderBottomRightRadius: '10px', // Borda arredondada na parte inferior direita
            }}>
              <input
                value={textoMensagem}
                onChange={(e) => setTextoMensagem(e.target.value)}
                placeholder="Digite sua mensagem"
                style={{
                  flex: 1,
                  height: '50px', 
                  maxWidth: "400px",
                  borderColor: '#E5E5EA',
                  backgroundColor: "white",
                  borderWidth: 1,
                  borderRadius: '10px', 
                  padding: '0 10px',
                }}
              />
              <Button onClick={enviarMensagem}>
                <span>Enviar</span>
              </Button>
            </div>
          </div>
          
          
    );
}
