import React, { useState } from 'react';
import './pages_documentos.css';

export default function ContraCheque() {
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };

  return (
    <main>
    <section className="ftco-section">
      <div className="container">
      <br /> <br />
        <div className="row">
          <div className="col-md-12">
            {!iframeLoaded && (
              <div className="loading-overlay">
                <p className="loading-text">Conectando...</p>
              </div>
            )}
            <iframe
            
              src="https://sistema.grupofbrito.com.br/portalservidor/?Ini=PMGJ"
              style={{ border: '0px solid #ccc', width: '100%', height: '100vh' }} 
              title="Contra-Cheque"
              onLoad={handleIframeLoad}
            ></iframe>
            <div className="baixeoapp">
              <img className="baixe" src="/imagens/app.png" alt="Baixe o App" />
            </div>
          </div>
        </div>
        <br /> <br /> 
      </div>
    </section>
  </main>
  );
}
