import React, { useState } from 'react';
import './pages_documentos.css';

export default function GurjaoPlus() {
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };

  return (
    <main>
      <section className="ftco-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 text-center mb-5">
              <br /> <br />
              <div className="linha"><span>Conheça o App Gurjão Plus</span></div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {!iframeLoaded && (
                <div className="loading-overlay">
                  <p className="loading-text">Carregando conteúdo, por favor aguarde...</p>
                </div>
              )}
              <iframe
                src="https://gurjaoplus.bigstarsoftwares.com.br/"
                style={{ border: '0px solid #ccc', width: '100%', height: '6500px' }}
                title="Gurjão Plus"
                onLoad={handleIframeLoad}
              ></iframe>
            </div>
          </div>
          <br /> <br /> <br /> <br />
        </div>
      </section>
    </main>
  );
}
