import React, { useState } from 'react';
import './pages_documentos.css';

export default function NotaFiscal() {
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };

  return (
    <main>
    <section className="ftco-section">
      <div className="container">
      <br />
      <div class="text-with-gradient" style={{ marginBottom: 15 }}>
                <div class="gradient-line"></div>
                <div class="text-container">
                  <span>EMISSOR DE NOTAS FISCAIS</span>
                </div>
                <div class="gradient-line"></div>
              </div>
        <div className="row">
          <div className="col-md-12">
            {!iframeLoaded && (
              <div className="loading-overlay">
                <p className="loading-text">Conectando...</p>
              </div>
            )}
            <iframe
            
            src="https://enfse.elmartecnologia.com.br/201084"
              style={{ border: '0px solid #ccc', width: '100%', height: '1500px' }} 
              title="Contra-Cheque"
              onLoad={handleIframeLoad}
            ></iframe>
            <div className="baixeoapp">
              <img className="baixe" src="/imagens/app.png" alt="Baixe o App" />
            </div>
          </div>
        </div>
        <br /> <br /> 
      </div>
    </section>
  </main>

   
  );
}
