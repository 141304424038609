import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import Noticias from "./Noticias";
import Videos from "./Videos";
import LayoutBanner from "./LayoutBanner";
import { getDatabase, ref, onValue } from 'firebase/database';
import firebaseApp, { KEY_REF } from './adm/admin/firebaseConfig';
import { Link } from 'react-router-dom';
import Meio from './Meio';
import BannerRH from './BannerRH';
import GoTopo from './GoTopo';

function removePTags(html) {

  return html.replace(/<p>/g, '').replace(/<\/p>/g, '').replace(/<ol>.*?<\/ol>/gs, '').replace(/<li>.*?<\/li>/gs, '');
}

function Novidades() {

  const [noticias, setNoticias] = useState([]);
  const [avisos, setAvisos] = useState([]);


  useEffect(() => {
    const databaseInstance = getDatabase(firebaseApp);
    const noticiasRef = ref(databaseInstance, `${KEY_REF}/licitacoes`);

    onValue(noticiasRef, (snapshot) => {
      const data = snapshot.val();

      if (data) {
        const noticiasArray = Object.keys(data).map((key) => ({
          id: key,
          date: data[key].data,
          titulo: data[key].titulo,
          descricao: data[key].descricao,
          numero: data[key].numero,
          modalidade: data[key].modalidade,
          arquivo: data[key].arquivo,
          horario: data[key].horario,
          documentoUrl: data[key].documentoUrl,
          ativo: data[key].ativo,
        }));

        const noticiasAtivas = noticiasArray.filter((noticia) => noticia.ativo);

        const noticiasOrdenadas = noticiasAtivas.sort((a, b) => new Date(b.data) - new Date(a.data));

        setNoticias(noticiasOrdenadas);
      }
    });

    const avisosRef = ref(databaseInstance, `${KEY_REF}/avisos_sociais`);
    onValue(avisosRef, (snapshot) => {
      const data = snapshot.val();

      if (data) {
        const avisosArray = Object.keys(data).map((key) => ({
          id: key,
          date: data[key].data,
          titulo: data[key].titulo,
          descricao: data[key].descricao,
          numero: data[key].numero,
          modalidade: data[key].modalidade,
          arquivo: data[key].arquivo,
          baixavel: data[key].baixavel,
          horario: data[key].horario,
          documentoUrl: data[key].documentoUrl,
          ativo: data[key].ativo,
        }));

        const avisosAtivos = avisosArray.filter((aviso) => aviso.ativo);
        const avisosOrdenados = avisosAtivos.sort((a, b) => new Date(b.data) - new Date(a.data));

        setAvisos(avisosOrdenados);
      }
    });
  }, []);




  const renderNoticias = (noticia) => {
    const truncatedDescricao = noticia.descricao.length > 50
      ? noticia.descricao.slice(0, 800) + "..."
      : noticia.descricao;

    const tituloParaURL = noticia.titulo.replace(/\s+/g, '-').toLowerCase();

    const descricaoSemPTags = removePTags(truncatedDescricao);

    return (
      <div class="d-flex text-body-secondary pt-3" key={noticia.id}>
        <a class="bd-placeholder-img flex-shrink-0 me-2 rounded" width="60" height="60"><i class="fa-solid fa-book fa-xl"></i></a>
        <p class="pb-3 mb-0 small lh-sm border-bottom" style={{ marginLeft: "1%", marginRight: "2%" }}>
          <strong class="d-block text-gray-dark">{noticia.titulo} - {noticia.numero}</strong>
          <br />{truncatedDescricao}
          <strong class="d-block text-gray-dark"><br />{noticia.date} - {noticia.horario} - {noticia.modalidade}  <a href={noticia.documentoUrl}> - <i class="fa-solid fa-file-arrow-down fa-beat-fade"></i> BAIXE O EDITAL AQUI</a></strong>
          <strong class="d-block text-gray-dark"></strong>
        </p>

      </div>

    );
  };

  const renderAvisos = (noticia) => {
    const truncatedDescricao = noticia.descricao.length > 50
      ? noticia.descricao.slice(0, 800) + "..."
      : noticia.descricao;

    const tituloParaURL = noticia.titulo.replace(/\s+/g, '-').toLowerCase();

    const descricaoSemPTags = removePTags(truncatedDescricao);

    return (
      <div class="d-flex text-body-secondary pt-3" key={noticia.id}>
        <a class="bd-placeholder-img flex-shrink-0 me-2 rounded" width="60" height="60"><i class="fa-solid fa-quote-left"></i></a>
        <p class="pb-3 mb-0 small lh-sm border-bottom" style={{ marginLeft: "1%", marginRight: "2%" }}>
          <strong class="d-block text-gray-dark">{noticia.titulo} - {noticia.numero}</strong>
          <br />{truncatedDescricao}
          <strong class="d-block text-gray-dark"><br />{noticia.date} - {noticia.horario} - {noticia.modalidade} <a href={noticia.documentoUrl}> {noticia.baixavel ? <span> - <i class="fa-solid fa-file-arrow-down fa-beat-fade"></i> ARQUIVO BAIXÁVEL</span> : null}</a></strong>
          <strong class="d-block text-gray-dark"></strong>
        </p>
      </div>
    );
  };


  const renderUltimosAvisos = () => {
    if (avisos.length === 0) {
      return (
        <div class="alert alert-warning" role="alert" style={{ backgroundColor: '#F5F5F5', color: 'black', fontWeight: 'bold' }}>
          Não há eventos sociais ou avisos importantes para os próximos dias. Verifique novamente mais tarde.
        </div>
      );
    }

    const ultimasNoticias = avisos.slice().reverse();
    return ultimasNoticias.map(renderAvisos);
  };

  const renderUltimasNoticias = () => {
    if (noticias.length === 0) {
      return (
        <div class="alert alert-warning" role="alert" style={{ backgroundColor: '#F5F5F5', color: 'black', fontWeight: 'bold' }}>
          Não há licitações disponíveis nos próximos dias. Verifique novamente mais tarde.
        </div>
      );
    }

    const ultimasNoticias = noticias.slice(-3);
    return ultimasNoticias.map(renderNoticias);
  };



  return (


    <>
      <main class="container" style={{ marginTop: 25 }}>


        <div class="my-3 p-3 bg-body rounded shadow-sm">
          <div class="d-flex align-items-center p-3 text-white bg-primary rounded shadow-sm" style={{ marginBottom: 25 }}>
            <a width="100" height="100"><i class="fa-solid fa-comments"></i></a>

            <div class="lh-1" style={{ marginLeft: "1%" }}>
              <h7 style={{ fontWeight: 'bold' }}>AVISOS SOCIAIS E EVENTOS</h7>
            </div>
          </div>
          <h6 class="border-bottom pb-2 mb-0">Mantenha-se informado por dentro das últimas novidades e eventos importantes em nossa comunidade a ser realizada.</h6>

          {renderUltimosAvisos()}

          <small class="d-block text-end mt-3">
            <Link to={"/avisos"}>TODOS OS AVISOS</Link>

          </small>
        </div>

      </main>

      <div className="text-center" style={{ marginTop: 20, marginBottom: 20, marginLeft: 5, marginRight: 5 }}>

        <Link to={"/legislacao/aldir_blanc"}>
          <img
            src="/imagens/aldirblanc.png"
            className="img-fluid"
            alt="Banner"
            style={{ width: "1250px", height: "auto", borderRadius: 5 }}
          />
        </Link>

      </div>




      <main class="container">


        <div class="my-3 p-3 bg-body rounded shadow-sm">
          <div class="d-flex align-items-center p-3 text-white bg-primary rounded shadow-sm" style={{ marginBottom: 25 }}>
            <a width="100" height="100"><i class="fa-solid fa-handshake"></i></a>

            <div class="lh-1" style={{ marginLeft: "1%" }}>
              <h7 style={{ fontWeight: 'bold' }}>AVISOS DE PROXIMAS LICITAÇÕES - CPL</h7>
            </div>
          </div>
          <h6 class="border-bottom pb-2 mb-0">Mantenha-se informado sobre as próximas licitações, marcando presença nas datas importantes.</h6>

          {renderUltimasNoticias()}

          <small class="d-block text-end mt-3">
            <Link to={"/licitacoes"}>TODAS AS LICITAÇÕES</Link>

          </small>
        </div>

      </main>


      <div className="text-center" style={{ marginLeft: 5, marginRight: 5 }}>
        <img
          src="/imagens/bannergov2.gif"
          className="img-fluid"
          alt="Banner"
          style={{ width: "1250px", height: "auto", borderRadius: 5 }}
        />
      </div>

      <main class="container">


        <div class="my-3 p-3 bg-body rounded shadow-sm">

          <div class="d-flex align-items-center p-3 text-white bg-primary rounded shadow-sm" style={{ marginBottom: 25 }}>
            <a width="100" height="100"><i class="fa-solid fa-circle-exclamation"></i></a>
            <div class="lh-1" style={{ marginLeft: "1%" }}>
              <h7 style={{ fontWeight: 'bold' }}>ATUALIZAÇÕES DO PORTAL - GURJÃO</h7>
            </div>
          </div>
          <h6 class="border-bottom pb-2 mb-0">Novidades e atualizações do Portal Governamental da Prefeitura de Gurjão</h6>
          <div class="d-flex text-body-secondary pt-3">
            <a class="bd-placeholder-img flex-shrink-0 me-2 rounded" width="50" height="50"><i class="fa-solid fa-star fa-2xl"></i></a>
            <p class="pb-3 mb-0 small lh-sm border-bottom" style={{ marginLeft: "2%", marginRight: "2%" }}>
              <strong class="d-block text-gray-dark">EMISSÃO DE 2º DOS BOLETOS DO IPTU 2022</strong>
              Agora, você pode acessar o portal oficial da Prefeitura de Gurjão no endereço https://www.gurjao.pb.gov.br/app/iptu e solicitar a sua segunda via em apenas alguns cliques. Essa nova funcionalidade visa facilitar a vida dos contribuintes, proporcionando mais comodidade e agilidade no processo de obtenção do documento. Acesse agora e aproveite essa comodidade! </p>
          </div>


          <div class="d-flex text-body-secondary pt-3">
            <a class="bd-placeholder-img flex-shrink-0 me-2 rounded" width="50" height="50"><i class="fa-solid fa-shield-halved fa-2xl"></i> </a>
            <p class="pb-3 mb-0 small lh-sm border-bottom" style={{ marginLeft: "2%", marginRight: "2%" }}>
              <strong class="d-block text-gray-dark">PORTAL COM SEGURANÇA SSL</strong>
              É com grande satisfação que informamos que o portal da cidade de Gurjão agora conta com a segurança SSL, garantindo a proteção dos seus dados durante a navegação. Com a implementação dessa camada adicional de segurança, todas as informações transmitidas entre o seu dispositivo e o portal são criptografadas, proporcionando uma experiência online mais segura e protegida. Assim, pode explorar todas as funcionalidades do portal com a certeza de que seus dados estão resguardados. A segurança dos seus dados é a nossa prioridade!</p>
          </div>

          <div class="d-flex text-body-secondary pt-3">
            <a class="bd-placeholder-img flex-shrink-0 me-2 rounded" width="50" height="50"><i class="fa-solid fa-globe fa-2xl"></i></a>

            <p class="pb-3 mb-0 small lh-sm border-bottom" style={{ marginLeft: "2%", marginRight: "2%" }}>
              <strong class="d-block text-gray-dark">EMISSÃO DE NOTAS FISCAIS ELETRONICAS</strong>
              Estamos empolgados em anunciar uma nova funcionalidade em nosso portal! Agora, empresas de porte ME ou superior podem emitir suas notas fiscais eletrônicas diretamente pelo portal, tornando o processo mais ágil e conveniente. Acesse o link https://www.gurjao.pb.gov.br/app/notasfiscais e descubra a facilidade de emitir suas notas fiscais de forma eficiente e segura. Simplificamos o processo para que você possa se concentrar no que realmente importa: o crescimento do seu negócio. Explore essa novidade agora mesmo!</p>
          </div>

          <div class="d-flex text-body-secondary pt-3">
            <a class="bd-placeholder-img flex-shrink-0 me-2 rounded" width="50" height="50"><i class="fa-solid fa-briefcase fa-2xl"></i></a>

            <p class="pb-3 mb-0 small lh-sm border-bottom" style={{ marginLeft: "2%", marginRight: "2%" }}>
              <strong class="d-block text-gray-dark">SALA DO EMPREENDENDOR DINAMICA</strong>
              Explore o novo e empolgante recurso da Sala do Empreendedor em Gurjão! Agora, oferecemos uma gama de cursos online e outras ferramentas valiosas para impulsionar o seu empreendimento. Acesse o link https://www.gurjao.pb.gov.br/saladoempreendedor e descubra uma plataforma repleta de recursos destinados a fortalecer seus conhecimentos e habilidades empresariais. Capacite-se de forma conveniente e flexível, diretamente do conforto do seu espaço de trabalho. Estamos comprometidos em apoiar o crescimento do seu negócio. Não perca a oportunidade de evoluir junto conosco na Sala do Empreendedor de Gurjão! </p>
          </div>

          <small class="d-block text-end mt-3">
            <a href="#">TODAS AS ATUALIZAÇÕES</a>
          </small>
        </div>
      </main>


      <div className="text-center" style={{ marginLeft: 5, marginRight: 5 }}>
        <img
          src="/imagens/bannergov1.gif"
          className="img-fluid"
          alt="Banner"
          style={{ width: "1250px", height: "auto", borderRadius: 5 }}
        />
      </div>

    </>
  );
}

export default Novidades;
