import React, { useState } from 'react';
import './css/Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faDribbble, faLinkedin, faYoutube, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import { Typography, List, ListItem, ListItemText, Icon, Box, Snackbar, IconButton } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import InfoIcon from '@mui/icons-material/Info';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CloseIcon from '@mui/icons-material/Close';

function Footer() {
  const [open, setOpen] = useState(false);
  const [copiedText, setCopiedText] = useState('');

  const handleCopy = (text) => {
    setCopiedText(text);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <footer class="site-footer">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <h6>Sobre <i class="fa-solid fa-circle-info"></i></h6>
            <p class="text-justify">O Município de Gurjão está situado na Microrregião do Cariri Oriental, distante 218.10 km da Capital João Pessoa. Possui uma área territorial de 335.45 km² e limita-se com os municípios de Soledade, Boa Vista, São João do Cariri, Parari, Santo André e Juazeirinho. Em 1669 com a doação de uma sesmaria, foi iniciada a sua colonização. Seu nome primitivo foi Timbaúba do Gurjão, depois Timbaúba, e simplesmente Gurjão, em homenagem ao Coronel Antônio José de Farias Gurjão, proprietário das terras do primeiro núcleo populacional. Duas grandes epidemias de cólera ocorreram na região uma em 1856 e outra no ano de 1862, ocasião em que foi feita uma promessa a São Sebastião, caso o local não fosse atingido pelo mal, construiriam em sua homenagem a capela e o escolheriam como padroeiro. A graça foi alcançada e os moradores logo se apressaram em cumprir a promessa. Este acolhedor município está situado na zona mais seca do estado, e suas principais atividades econômicas são: a agropecuária, o artesanato e, em especial, a caprinovinocultura – principal fonte de subsistência da cidade.</p>

          </div>

          <div class="col-xs-6 col-md-3">
            <h6>Secretarias <i class="fa-solid fa-user"></i></h6>
            <ul class="footer-links">
              <Link to={"/secretarias/administracao"} className="dropdown-item">Administração</Link>
              <Link to={"/secretarias/assistenciasocial"} className="dropdown-item">Assistencia Social</Link>
              <Link to={"#"} className="dropdown-item">Controladoria</Link>
              <Link to={"/secretarias/cultura"} className="dropdown-item">Cultura e Turismo</Link>
              <Link to={"/secretarias/desenvolvimentorural"} className="dropdown-item">Desenvolvimento Rural</Link>
              <Link to={"/secretarias/educacao"} className="dropdown-item">Educação</Link>
              <Link to={"/secretarias/financas"} className="dropdown-item">Finanças</Link>
              <Link to={"#"} className="dropdown-item">Infraestrutura</Link>
              <Link to={"#"} className="dropdown-item">Recursos Humanos</Link>
              <Link to={"/secretarias/saude"} className="dropdown-item">Saúde</Link>

            </ul>
          </div>

          <div class="col-xs-4 col-md-3">
            <h6>Links úteis <i class="fa-solid fa-user"></i></h6>
            <ul class="footer-links">
              <li><Link to={"/arquivos/contratos"}>Contratos</Link></li>
              <li><Link to={"/arquivos/frota"}>Frota Municipal</Link></li>
              <li><Link to={"/rh/cc"}>Contra-cheque</Link></li>
              <li><Link to={"/transparencia"}>Transparência Fiscal</Link></li>
              <li><Link className="dropdown-item" to={"/legislacao/codigotributario"}>Codigo Tributario</Link></li>

            </ul>

            <Box sx={{ padding: '16px', backgroundColor: '#3296DE', borderRadius: '8px' }}>
              <Typography variant="h6" gutterBottom sx={{ color: '#FFFFFF' }}>
                Contatos <InfoIcon sx={{ color: '#FFFFFF' }} />
              </Typography>
              <List>
                <CopyToClipboard text="municipiogurjaopb@gmail.com" onCopy={() => handleCopy('E-mail')}>
                  <ListItem button>
                    <EmailIcon sx={{ marginRight: '8px', color: '#FFFFFF' }} />
                    <ListItemText primaryTypographyProps={{ color: '#FFFFFF' }} primary="municipiogurjaopb@gmail.com" />
                  </ListItem>
                </CopyToClipboard>
                <CopyToClipboard text="+55 83 8232-9224" onCopy={() => handleCopy('Telefone')}>
                  <ListItem button>
                    <PhoneIcon sx={{ marginRight: '8px', color: '#FFFFFF' }} />
                    <ListItemText primaryTypographyProps={{ color: '#FFFFFF' }} primary="+55 83 8232-9224" />
                  </ListItem>
                </CopyToClipboard>
              </List>
              <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                message={`${copiedText} copiado para a área de transferência`}
                action={
                  <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </React.Fragment>
                }
              />
            </Box>
          </div>
        </div>
        <hr></hr>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-sm-6 col-xs-12">
            <p class="copyright-text">Copyright &copy; 2024 - Prefeitura Municipal de Gurjão | CNPJ: 09.073.685/0001-70 | Controle de dados
              <a href="https://www.conectadocomanoticia.com.br"> <i class="fa-brands fa-dev fa-fade"></i> AGENCIA CONECTADO LTDA.</a>
            </p>
          </div>

          <div class="col-md-2 col-sm-6 col-xs-12">

            <ul class="social-icons">
              <li><a className="facebook" href="https://www.facebook.com/prefeituradegurjaopb/"><FontAwesomeIcon icon={faFacebook} /></a></li>
              <li><a className="instagram" href="https://www.instagram.com/prefeituradegurjaopb/"><FontAwesomeIcon icon={faInstagram} /></a></li>
              <li><a className="youtube" href="https://www.youtube.com/channel/UCI-aO0WbravcPGZm9VOVvuw"><FontAwesomeIcon icon={faYoutube} /></a></li>
              <li><a className="whatsapp" href="#"><FontAwesomeIcon icon={faWhatsapp} /></a></li>
            </ul>

          </div>
        </div>
      </div>
    </footer>

  );
}

export default Footer;
