import React, { useState } from 'react';


export default function Financas() {

    const [secretario] = useState({
        nome: 'Thiago Reis da silva',
        descricao: 'O Secretário de Finanças de um município desempenha um papel central na gestão financeira. Suas responsabilidades incluem o planejamento e execução do orçamento, gestão de receitas e despesas, manutenção de registros contábeis, conformidade com regulamentações, relações com órgãos de controle e participação em negociações. Além disso, ele supervisiona auditorias internas, gerencia a dívida municipal, promove transparência nas finanças e garante prestação de contas à comunidade. O secretário também pode estar envolvido no treinamento da equipe em assuntos financeiros, contribuindo para a estabilidade e desenvolvimento sustentável do município.',
        foto: '/imagens/thiago.jpeg',
      });
    

  return (
    <div className="container my-4 mx-auto" style={{ maxWidth: '1600px' }}>
    <div className="row justify-content-center">
      <div className="col-md-6">
        <div className="card">
          <div className="row">
            <div className="col-md-6">
              <img
                src={secretario.foto}
                alt={secretario.nome}
                className="card-img-top img-fluid d-none d-md-block"
                style={{ margin:"5%", maxWidth: '500px' }}
              />

              <div style={{margin:"5%", marginLeft:"9%"}}>
              <button onClick="" type="button" class="btn btn-sm btn-outline-secondary d-none d-md-inline">
      <i class="fa-solid fa-square-envelope"></i> E-MAIL
    </button>
    
    <button onClick="" type="button" class="btn btn-sm btn-outline-secondary d-none d-md-inline">
      <i class="fa-solid fa-phone" style={{ color: "#27b300" }}></i> TELEFONE
    </button>
    <button onClick="" type="button" class="btn btn-sm btn-outline-secondary d-none d-md-inline">
      <i class="fa-solid fa-clock"></i> HORÁRIOS
    </button>
              </div>
      
            </div>
            <div className="col-md-6">
  <div className="card-body">
    <h3 className="d-none d-md-block card-title">{secretario.nome}</h3>
    <p className="d-none d-md-block card-text text-justify" style={{padding: "1%"}}>{secretario.descricao}</p>
   
    
  </div>
</div>

          </div>
          <div className="d-md-none text-center">
            <img
              src={secretario.foto}
              alt={secretario.nome}
              className="card-img-top img-fluid"
              style={{ maxWidth: '300px' }}
            />
            <div className="text-center mt-3">
              <h3 className="card-title">{secretario.nome}</h3>
              <p className="card-text text-justify " style={{padding: "5%"}}>{secretario.descricao}</p>
   

              <button onClick="" type="button" class="btn btn-sm btn-outline-secondary"><i class="fa-solid fa-square-envelope"></i> E-MAIL</button>
                <button onClick="" type="button" class="btn btn-sm btn-outline-secondary"><i class="fa-solid fa-phone" style={{color: "#27b300"}}></i> TELEFONE </button>
                <button onClick="" type="button" class="btn btn-sm btn-outline-secondary"><i class="fa-solid fa-clock"></i> HORARIOS</button>

          </div>
            </div>
        
        </div>
      </div>
    </div>
  </div>
  
  
  
  );
}
